<div *ngIf="(functionalDeclaration$ | async)?.entity as functionalDeclaration">
	<ng-container *ngIf="entityCanAccessPremiumFeatures(functionalDeclaration)">
		<div class="d-flex" style="flex-direction: column" *ngIf="mutableState$ | async as state">
			<div>
				<div class="d-flex gap-2" style="justify-content: space-between; margin-top: 20px">
					<div style="flex: 1">
						<div *ngIf="functionalDeclaration && !(functionalDeclaration | isAssujetti)">
							<mat-checkbox
								class="checkbox-white-space-none"
								style="overflow-wrap: normal"
								[checked]="!functionalDeclaration.not_in_csv"
								(change)="toggleNotInCsv(functionalDeclaration)"
							>
								Inclure cette entité fonctionnelle dans la génération des fichiers CSV </mat-checkbox
							><app-help [subject]="HelpSubject.EfaInCsv"></app-help>
						</div>
						<div>
							<h4 class="mb-0">Selectionner la parcelle principale:</h4>
							<ng-select
								class="w-100"
								[clearable]="false"
								[(ngModel)]="state.mainParcel"
								(ngModelChange)="setMainParcel(functionalDeclaration, $event)"
							>
								<ng-option *ngFor="let parcel of functionalDeclaration.infos.parcelles" [value]="parcel | parcelId">
									{{ parcel | parcelName }}
								</ng-option>
							</ng-select>
						</div>
						<div tourAnchor="asset-meteo-station">
							<h4 class="mb-0">
								Selectionner la station météo la plus appropriée
								<app-help [subject]="HelpSubject.WeatherStation"></app-help>
								:
							</h4>
							<ng-select
								class="w-100"
								[clearable]="false"
								[(ngModel)]="state.station"
								(ngModelChange)="setStation(functionalDeclaration, $event)"
							>
								<ng-container *ngFor="let station of stations$ | async">
									<ng-option
										*ngFor="
											let label of [
												station.name +
													' (alt: ' +
													station.altitude +
													'm ' +
													(station.description ? ' / ' + station.description : '') +
													', département: ' +
													(station.code + '' | slice: 0 : 2) +
													')'
											]
										"
										[value]="station.code"
									>
										<div class="option" [title]="label">{{ label }}</div>
									</ng-option>
								</ng-container>
							</ng-select>
						</div>
						<div tourAnchor="asset-energy">
							<h4 class="mb-0">
								Types d'énergie utilisés :
								<app-help [subject]="HelpSubject.EnergyTypes"></app-help>
							</h4>
							<ng-select
								[multiple]="true"
								[closeOnSelect]="false"
								[clearable]="false"
								[(ngModel)]="state.energyTypes"
								(ngModelChange)="setEnergyTypes(functionalDeclaration, $event)"
								#energyType
							>
								<ng-option *ngFor="let type of ENERGY_TYPES" [value]="type">
									{{ type | energyTypeTitle }}
								</ng-option>
							</ng-select>
							<div class="custom-input-error d-block">{{ energyType.searchInput.nativeElement.validationMessage }}</div>
						</div>
					</div>
					<div style="flex: 2">
						<app-asset-map
							tourAnchor="map-main-plot"
							*ngIf="centerMap$ | async as center"
							[center]="center"
							[parcels]="functionalDeclaration.infos.parcelles"
							[selectedParcelId]="functionalDeclaration.infos.asset.mainParcel ?? null"
							(selectParcelId)="setMainParcel(functionalDeclaration, $event)"
							(mapLoad)="($event)"
						>
						</app-asset-map>
					</div>
				</div>
			</div>
			<div>
				<div tourAnchor="asset-agreements">
					<ng-container *ngIf="functionalDeclaration.infos.asset.energyType.length !== 0">
						<h4 class="mb-0">Récupération automatique des données :</h4>
						<span class="text-gray"
							>Le Configurateur permet d'accéder automatiquement aux données de consommations auprès d'Enedis et GRDF.
							Renseignez les PDL concernés afin de les récupérer.</span
						>
						<div class="d-flex gap-2" *ngIf="prmsAndPces$ | async as prmsAndPces">
							<div
								class="w-50"
								*ngIf="functionalDeclaration.infos.asset.energyType.includes(EnergyType.ELECTRICITY_KWH)"
							>
								<app-agreement-container
									[agreements]="prmsAndPces.prms"
									color="accent"
									listTitle="Mes PRM"
									icon="bolt"
									[canEdit]="true"
									[canAdd]="true"
									(addAction)="addPrm(functionalDeclaration)"
									(editAction)="
										editAgreement(
											functionalDeclaration,
											$event.agreement,
											$event.index,
											prmsAndPces.prms,
											prmsAndPces.pces
										)
									"
								></app-agreement-container>
							</div>
							<div
								class="w-50"
								*ngIf="functionalDeclaration.infos.asset.energyType.includes(EnergyType.GAZ_NAT_NETWORK_KWH)"
							>
								<app-agreement-container
									[agreements]="prmsAndPces.pces"
									color="accent2"
									listTitle="Mes PCE"
									icon="local_fire_department"
									[canEdit]="true"
									[canAdd]="true"
									(addAction)="addPce(functionalDeclaration)"
									(editAction)="
										editAgreement(
											functionalDeclaration,
											$event.agreement,
											$event.index,
											prmsAndPces.prms,
											prmsAndPces.pces
										)
									"
								></app-agreement-container>
							</div>
						</div>
					</ng-container>
				</div>

				<div *ngIf="stepError !== undefined" class="danger step-error" id="stepErrorDiv">
					{{ stepError | reasonToText }}
				</div>
				<div
					*ngIf="functionalDeclaration.infos.asset.mainParcel || functionalDeclaration.is_imported_from_operat"
					tourAnchor="asset-surfaces"
					class="mb-2"
					[class.step-error-content]="stepError !== undefined"
				>
					<h4 class="mb-0">Catégories d’activité et surfaces</h4>
					<div class="d-flex mb-2" style="align-items: center">
						<label class="mr-3">Catégorie d'activité principale</label>
						<ng-select
							class="w-25 no-dropdown-width"
							[(ngModel)]="state.mainCategory"
							name="category"
							required
							(ngModelChange)="setMainCategory(functionalDeclaration, $event)"
						>
							<ng-option *ngFor="let category of CATEGORIES" [value]="category.code">
								<div style="overflow: hidden; text-overflow: ellipsis" [title]="category.libelle">
									{{ category.libelle }}
								</div>
							</ng-option>
						</ng-select>
					</div>
					<div class="d-flex mb-2 gap-2" style="align-items: center">
						<label style="white-space: nowrap">Surface des locaux techniques (m²)</label>
						<input
							#inputLocalArea
							[value]="functionalDeclaration.infos.asset.localArea"
							(input)="setLocalArea(functionalDeclaration, inputLocalArea.value)"
							class="custom-input"
							type="number"
							appInputRestriction="integer"
							min="0"
						/>
						<span class="text-gray">
							Cette surface doit être intégrée aux surfaces des catégories d'activité déclarées dans le tableau
							ci-dessous (répartie entre les catégories ou intégrée à la catégorie la plus grande).
						</span>
					</div>
					<ng-container *ngIf="assujettiSurface$ | async as assujettiSurface">
						<div
							class="danger d-flex"
							*ngIf="
								functionalDeclaration.infos.asset.categories.length !== 0 &&
								(assujettiSurface -
									((functionalDeclaration
										| sumCategoryPeriod: todayTimestamp - 1 : todayTimestamp : [STATIONNEMENT_CATEGORY]) -
										(functionalDeclaration.infos.asset.localArea ?? 0)) | abs) >=
									assujettiSurface * 0.05
							"
						>
							<mat-icon>warning</mat-icon>
							La somme des surfaces de vos catégories d'activité ne correspond pas à la surface tertiaire renseignée
							dans l'étape précédente. ({{ assujettiSurface }}m²
							<ng-container *ngIf="functionalDeclaration.infos.asset.localArea">
								+ {{ functionalDeclaration.infos.asset.localArea }}m² de locaux technique
							</ng-container>
							)
						</div>
					</ng-container>
					<ng-container *ngIf="usedDefaultCategory$ | async as usedDefaultCategory">
						<div
							class="warning d-flex"
							*ngIf="usedDefaultCategory && this.functionalDeclaration.infos.asset.categories.length === 1"
						>
							<mat-icon>warning</mat-icon>
							Lorsque vous utilisez une catégorie "par défaut", vous ne pouvez avoir qu'une seule catégorie d'activité.
						</div>

						<ng-container *ngIf="categoryDatesConflicts$ | async as categoryDatesConflicts">
							<ng-container
								*ngFor="let categories of [functionalDeclaration.infos.asset.categories | uniqueSubCategoriesPerName]"
							>
								<div class="danger d-flex" *ngIf="categoryDatesConflicts?.length">
									<span><mat-icon>warning</mat-icon></span>
									<span
										>Si vous utilisez une catégorie "par défaut", celle-ci doit être la seule catégorie d'activité
										déclarée sur l'année. Veuillez supprimer les autres catégories d'activité conflictuelles avant de
										continuer.</span
									>
								</div>
								<div
									class="warning d-flex"
									*ngIf="
										usedDefaultCategory &&
										(categories.length !== 1 || categories[0].entries.length > 1) &&
										categoryDatesConflicts?.length === 0
									"
								>
									<span><mat-icon>warning</mat-icon></span>
									<span>
										Attention, vous avez mêlé des catégories d'activité par défaut avec d'autres catégories d'activité.
										Assurez-vous que sur chaque période déclarative (chaque année), les catégories d'activité par défaut
										ne sont pas mêlées à d'autres catégories d'activité. Si une catégorie d'activité par défaut est
										utilisée, elle doit couvrir l'entièreté de l'année concernée et être la seule activité déclarée
										cette année.
									</span>
								</div>
								<div
									class="warning d-flex"
									*ngIf="
										usedDefaultCategory &&
										categories.length === 1 &&
										categories[0].entries.length === 1 &&
										categories[0].entries[0].activityCategoryEntries.length > 1
									"
								>
									<mat-icon>warning</mat-icon>
									<span>
										Attention, pour une même année, les caractéristiques d'une catégorie d'activité
										<b>par défaut</b> doivent être uniformes. Changer par exemple de surface au milieu de l'année
										provoquerait le rejet du fichier par OPERAT.
									</span>
								</div>
							</ng-container>
						</ng-container>
					</ng-container>

					<div
						class="danger d-flex"
						*ngIf="
							!(
								functionalDeclaration.infos.asset.mainCategory
								| inCategories: functionalDeclaration.infos.asset.categories
							)
						"
					>
						<mat-icon>warning</mat-icon>
						Votre catégorie principale ne fait pas partie de vos catégories déclarées.
					</div>

					<div
						class="warning d-flex"
						*ngIf="!(STATIONNEMENT_CATEGORY | inCategories: functionalDeclaration.infos.asset.categories)"
					>
						<mat-icon>warning</mat-icon>
						Si vous avez du stationnement intérieur, pensez à renseignez les surfaces correspondantes dans la catégorie
						"stationnement" et la sous-catégorie qui convient.
					</div>

					<div>
						<app-activity-category-table
							[functionalDeclaration]="functionalDeclaration"
							(addCategoryAction)="addCategoryModal(functionalDeclaration, $event)"
							(editCategoryAction)="editCategoryModal(functionalDeclaration, $event)"
							(addElectricDockAction)="addElectricCarDockModal(functionalDeclaration, $event)"
							(editElectricDockAction)="editElectricCarDockModal(functionalDeclaration, $event)"
							[conflictedCategories$]="categoryDatesConflicts$"
						>
						</app-activity-category-table>
						<div class="d-flex" style="flex-wrap: wrap; justify-content: center">
							<button
								mat-button
								class="btn-primary"
								(click)="addCategoryModal(functionalDeclaration)"
								style="margin-right: 5px"
								*ngIf="canAddCategory$ | async"
							>
								<mat-icon>add</mat-icon>
								Ajout d'une catégorie d'activité
							</button>

							<button mat-button class="btn-primary-light" (click)="addElectricCarDockModal(functionalDeclaration)">
								<mat-icon>add</mat-icon>
								Ajout de bornes IRVE
							</button>
						</div>

						<div class="text-right">
							<span
								class="danger link"
								matTooltip="Une fausse déclaration est passible des sanctions prévues au R. 441-6 du code pénal (deux ans d'emprisonnement et 30 000 euros d'amende pour le déclarant). &quot;La sélection de sous-catégories non présentes sur l’entité fonctionnelle en vue de bénéficier d’objectifs plus facile à atteindre et permettant d’obtenir une meilleure notation Éco Énergie Tertiaire seront considérées comme des fausses déclarations.&quot;"
							>
								Sanctions
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<ng-template let-data #confirmDeleteEnergyTypeModal>
	<div class="dialog-container">
		<div class="dialog-close-icon">
			<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
		</div>
		<div class="dialog-content pt-0">
			<h3 class="mt-0 mb-2">Suppression de données de consommations</h3>
			<div>
				Vous êtes sur le point de supprimer les données de consommation de l'énergie : {{ data.typeLabel }}.<br />
				Si vous confirmez, les données pour les consommations suivantes seront supprimées :
				<ul *ngFor="let description of data.descriptions">
					<li>{{ description }}</li>
				</ul>
				Voulez vous continuer ?
			</div>
		</div>
		<div class="dialog-actions d-flex" style="justify-content: space-between">
			<button class="btn-text-gray-reverse" [mat-dialog-close]="false">Annuler</button>
			<button class="btn-stroked-primary" [mat-dialog-close]="true">Confirmer</button>
		</div>
	</div>
</ng-template>
