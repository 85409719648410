import { HttpClient, HttpErrorResponse, HttpParams, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GreenKeys } from "@grs/greenkeys";
import { Immutable } from "immer";
import { EMPTY, Observable, of, ReplaySubject } from "rxjs";
import { catchError, debounceTime, map, pairwise, startWith, switchMap, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Nullable } from "../helpers/nullable";
import { myForkjoin, toObservable } from "../helpers/to-observable";
import { unwrap } from "../helpers/unwrap";
import { ZodParser } from "../helpers/zod-parser";
import { SubCategoryCode } from "../models/asset";
import { cleanCategories, FunctionalDeclaration } from "../models/functionalDeclaration";
import { FunctionalDeclarationId } from "../models/ids";
import { FunctionalDeclarationStateService } from "./functional-declaration-state.service";

export interface Djus {
	[declarationFunctionalId: string]: { [year: string]: number };
}

export interface AbsoluteObjectives {
	[declarationFunctionalId: string]: { [year: string]: number };
}

@Injectable({
	providedIn: "root",
})
export class DeclarationFunctionalService {
	readonly DEBOUNCE_TIME = 500;
	readonly REFRESH_TIME = 5000; // 10 minutes

	readonly functionalDeclaration$ = new ReplaySubject<Immutable<FunctionalDeclaration | undefined>>(1);
	readonly saving$ = new ReplaySubject<boolean>(1);

	constructor(
		private http: HttpClient,
		private functionalDeclarationState: FunctionalDeclarationStateService,
	) {
		functionalDeclarationState.get$
			.pipe(
				debounceTime(this.REFRESH_TIME), // if the user is editing the entity, we reset the delay
				switchMap((entity) => (entity ? of(entity) : EMPTY)),
				switchMap(({ value: { declaration_functional_id } }) => {
					try {
						// the owner will delete its cache to fetch a new entity, we can safely ignore that as if an error occurs, it means that it is already cleared
						functionalDeclarationState.getOwner().clearCache();
					} catch (e) {
						/* empty */
					}
					// WARNING IT DOESN'T TRIGGER THE UPPER LAYERS SO THE STEPPER WON'T BE NOTIFIED IF PROGRESS WERE MADE (we want to avoid get nested calls every seconds)
					return functionalDeclarationState.select$(unwrap(declaration_functional_id), "refresh"); // will ask the owner to get a fresh entity
				}),
			)
			.subscribe();

		this.functionalDeclaration$
			.pipe(
				startWith(undefined),
				pairwise(),
				switchMap(([previousEntity, updatedEntity]) =>
					updatedEntity
						? of([previousEntity, updatedEntity] as const)
						: toObservable(() => {
								this.saving$.next(false);
							}).pipe(switchMap(() => EMPTY)),
				),
				switchMap(([previousEntity, updatedEntity]) =>
					myForkjoin([
						previousEntity && previousEntity.declaration_functional_id !== updatedEntity.declaration_functional_id
							? this.update$(FunctionalDeclaration.toApi(previousEntity)).pipe(
									catchError((error) => {
										// Can 404 if trying to update previous entity that has been deleted
										if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
											return of(undefined);
										}
										throw error;
									}),
								)
							: of(undefined),
						// catch error in case update resource throw, to prevent entity save stuck
						this.functionalDeclarationState.update$(updatedEntity).pipe(
							catchError(() => {
								this.saving$.next(false);
								return EMPTY;
							}),
						),
					]).pipe(map(() => updatedEntity)),
				),
				debounceTime(this.DEBOUNCE_TIME),
				switchMap((updatedEntity) => this.update$(FunctionalDeclaration.toApi(updatedEntity))),
				catchError((error) => {
					console.error(error);
					return of(undefined);
				}),
			)
			.subscribe(() => {
				this.saving$.next(false);
			});
	}

	update(functionalDeclaration: Immutable<FunctionalDeclaration>): void {
		this.saving$.next(true);
		this.functionalDeclaration$.next(functionalDeclaration);
	}

	getDju$(id: FunctionalDeclarationId): Observable<{ [year: string]: number }> {
		return this.http.get<{ [year: string]: number }>(`${environment.baseApiUrl}/declaration_functional/${id}/dju`);
	}

	getAbsoluteObjective$(id: FunctionalDeclarationId): Observable<{ [year: string]: number }> {
		return this.http.get<{ [year: string]: number }>(
			`${environment.baseApiUrl}/declaration_functional/${id}/absolute_objective`,
		);
	}

	getDjus$(ids: FunctionalDeclarationId[]): Observable<Djus> {
		return this.http.post<{ [declarationFunctionalId: string]: { [year: string]: number } }>(
			`${environment.baseApiUrl}/declaration_functional/dju`,
			ids,
		);
	}

	getAbsoluteObjectives$(ids: FunctionalDeclarationId[]): Observable<AbsoluteObjectives> {
		return this.http.post<{ [declarationFunctionalId: string]: { [year: string]: number } }>(
			`${environment.baseApiUrl}/declaration_functional/absolute_objective`,
			ids,
		);
	}

	get$(id: FunctionalDeclarationId): Observable<FunctionalDeclaration.Api> {
		return this.http.get<FunctionalDeclaration.Api>(`${environment.baseApiUrl}/declaration_functional/${id}`).pipe(
			map((functionalDeclaration) => {
				functionalDeclaration[GreenKeys.KEY_CONSUMPTIONS] = ZodParser.fixConsumptionsType(
					functionalDeclaration[GreenKeys.KEY_CONSUMPTIONS],
				);
				return functionalDeclaration;
			}),
			tap((functionalDeclaration) => {
				for (const building of functionalDeclaration.infos.buildingInfos) {
					cleanCategories(building.ownedSurface.categorySurfaceAreas);
					for (const notOwnedSurface of building.notOwnedSurfaces) {
						cleanCategories(notOwnedSurface.categorySurfaceAreas);
						if (notOwnedSurface.categorySurfaceAreas instanceof Array) {
							notOwnedSurface.categorySurfaceAreas = {};
						}
					}
					if (building.ownedSurface.categorySurfaceAreas instanceof Array) {
						// Mongo likes to send empty arrays instead of empty objects
						building.ownedSurface.categorySurfaceAreas = {};
					}
				}
				if (functionalDeclaration.infos.otherBuildingOnSameSite) {
					cleanCategories(functionalDeclaration.infos.otherBuildingOnSameSite.categorySurfaceAreas);
					if (functionalDeclaration.infos.otherBuildingOnSameSite.categorySurfaceAreas instanceof Array) {
						functionalDeclaration.infos.otherBuildingOnSameSite.categorySurfaceAreas = {};
					}
				}
			}),
		);
	}

	create$(declarationFunctional: Immutable<FunctionalDeclaration.Api>): Observable<FunctionalDeclaration.Api> {
		return this.http.post<FunctionalDeclaration.Api>(
			`${environment.baseApiUrl}/declaration_functional`,
			declarationFunctional,
		);
	}

	update$(declarationFunctional: Immutable<FunctionalDeclaration.Api>): Observable<FunctionalDeclaration.Api> {
		return this.http.put<FunctionalDeclaration.Api>(
			`${environment.baseApiUrl}/declaration_functional/${declarationFunctional.declaration_functional_id}`,
			declarationFunctional,
		);
	}

	delete$(id: FunctionalDeclarationId): Observable<void> {
		return this.http.delete<void>(`${environment.baseApiUrl}/declaration_functional/${id}`);
	}

	getConsumptionCsv$(id: FunctionalDeclarationId): Observable<string> {
		return this.http.get(`${environment.baseApiUrl}/declaration_functional/${id}/consumptions`, {
			responseType: "text",
		});
	}

	unlockYear$(
		id: Immutable<FunctionalDeclaration["declaration_functional_id"]> | undefined,
		year: number,
	): Observable<FunctionalDeclaration.Api> {
		return this.http.put<FunctionalDeclaration.Api>(`${environment.baseApiUrl}/declaration_functional/${id}/year`, {
			year: year,
		});
	}

	getDefaultAbsoluteObjective(
		code: SubCategoryCode,
		altitude?: number,
		zipCode?: string,
	): Observable<Nullable<number>> {
		let params = new HttpParams().set(GreenKeys.KEY_CODE, code);
		if (altitude) {
			params = params.set("altitude", altitude);
		}
		if (zipCode) {
			params = params.set("zipCode", zipCode);
		}

		return this.http
			.get<
				{ [GreenKeys.KEY_ABSOLUTE_OBJECTIVE]: number } | undefined
			>(`${environment.baseApiUrl}/declaration_functional/absolute_objective`, { params })
			.pipe(
				map((response) =>
					response && response[GreenKeys.KEY_ABSOLUTE_OBJECTIVE]
						? response[GreenKeys.KEY_ABSOLUTE_OBJECTIVE]
						: undefined,
				),
			);
	}
}
