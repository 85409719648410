import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { GreenKeys } from "@grs/greenkeys";
import { produce, castDraft, Immutable } from "immer";
import { EMPTY, of, Subscription } from "rxjs";
import { distinctUntilKeyChanged, first, map, switchMap } from "rxjs/operators";
import { DialogComponent, HelpSubject } from "src/app/components/help/help.component";
import { Reason } from "src/app/helpers/hasDoneStep";
import { unwrap } from "src/app/helpers/unwrap";
import { BaseComponent } from "src/app/models/base-component.directive";
import { Declaration } from "src/app/models/declaration";
import { EnergyCategory } from "src/app/models/energyCategory";
import { EnergyType, getDeliverables } from "src/app/models/energyType";
import {
	cloneFunctionalDeclaration,
	ConsumptionEntry,
	EnergyConsumptions,
	FunctionalDeclaration,
	isMultiOccupation,
} from "src/app/models/functionalDeclaration";
import { HeatModifierType } from "src/app/models/heatModifierType";
import { DeclarationId } from "src/app/models/ids";
import { dialogOpen } from "src/app/models/modal";
import { RouteDealer } from "src/app/models/routes";
import { canNotReachStep } from "src/app/pipes/can-reach-step.pipe";
import { CategoryKeyToLabelPipe } from "src/app/pipes/category-key-to-label.pipe";
import { DateToTimestampPipe } from "src/app/pipes/date-to-timestamp.pipe";
import { HeatModifierTypeDisplayPipe } from "src/app/pipes/heat-modifier-type-display.pipe";
import { MaxEndForEnergyTypePipe } from "src/app/pipes/max-end-for-energy-type.pipe";
import { MinStartForEnergyTypePipe } from "src/app/pipes/min-start-for-energy-type.pipe";
import { PeriodRangePipe } from "src/app/pipes/period-range.pipe";
import { SumInvoicePerYearPipe } from "src/app/pipes/sum-invoice-per-year.pipe";
import { YearForDateAndPeriodPipe } from "src/app/pipes/year-for-date-and-period.pipe";
import { DeclarationFunctionalService } from "src/app/services/declaration-functional.service";
import { DeclarationStateService } from "src/app/services/declaration-state.service";
import { FunctionalDeclarationStateService } from "src/app/services/functional-declaration-state.service";
import { TourStateService } from "src/app/services/tour-state.service";
import { MinDateWithDateStartEntity } from "../../../../../../helpers/min-date-with-date-start-entity";
import { Nullable } from "../../../../../../helpers/nullable";
import { range } from "../../../../../../helpers/array";
import { toSnakeCase } from "../../../../../../helpers/string";
import { DeclarationGroup } from "../../../../../../models/declarationGroup";
import { Lazy } from "../../../../../../models/lazy";
import { Representative } from "../../../../../../models/representative";
import { allConsumptionsIncomplete } from "../../../../../../pipes/all-consumptions-incomplete.pipe";
import { CategoryStartPipe } from "../../../../../../pipes/category-start.pipe";
import { DeclarationGroupStateService } from "../../../../../../services/declaration-group-state.service";
import { RepresentativeStateService } from "../../../../../../services/representative-state.service";
import { CONSO_PAGE } from "../../../stepper.component";
import { ConsumeTokenDialogComponent, ConsumeTokenMessageType } from "../../asset-declaration/consume-token-dialog";
import { ProofFilesModalsComponent } from "../proof-files-modals/proof-files-modals.component";
import { AddRowModalComponent } from "./add-row-modal/add-row-modal.component";
import { ConsumeAnnualTokenModalComponent } from "./consume-annual-token-modal/consume-annual-token-modal.component";
import MINIMUM_YEAR = Declaration.MINIMUM_YEAR;
import ANNUAL_DECLARATION_STARTING_YEAR = Declaration.ANNUAL_DECLARATION_STARTING_YEAR;

const minStartPipe = new MinStartForEnergyTypePipe();
const maxEndPipe = new MaxEndForEnergyTypePipe();
const periodRange = new PeriodRangePipe();
const dateToTimestamp = new DateToTimestampPipe();

@Component({
	selector: "app-consumption-table",
	templateUrl: "./consumption-table.component.html",
	styleUrls: ["./consumption-table.component.scss"],
})
export class ConsumptionTableComponent extends BaseComponent implements OnDestroy, OnInit {
	functionalDeclaration: FunctionalDeclaration | null = null;

	readonly DELIVERABLE = getDeliverables();
	readonly PERIOD_LIST = [
		"Janvier - Décembre",
		"Février - Janvier",
		"Mars - Février",
		"Avril - Mars",
		"Mai - Avril",
		"Juin - Mai",
		"Juillet - Juin",
		"Août - Juillet",
		"Septembre - Août",
		"Octobre - Septembre",
		"Novembre - Octobre",
		"Décembre - Novembre",
	];

	readonly MINIMUM_YEAR = Declaration.MINIMUM_YEAR;
	readonly MAXIMUM_YEAR = new Date().getFullYear();
	readonly MAXIMUM_YEAR_INITIAL_DECLARATION = Declaration.MAXIMUM_YEAR_INITIAL_DECLARATION;

	HEADERS_MONTHLY = ["Jan.", "Fév.", "Mars", "Avr.", "Mai", "Juin", "Juil.", "Août", "Sep.", "Oct.", "Nov.", "Déc."];

	get years(): number[] {
		return range(MINIMUM_YEAR + 1, this.MAXIMUM_YEAR + 1);
	}

	get annualDeclarationYears(): number[] {
		return range(ANNUAL_DECLARATION_STARTING_YEAR, this.MAXIMUM_YEAR + 1);
	}

	selectedIndex: number = this.years.indexOf(Declaration.ANNUAL_DECLARATION_STARTING_YEAR - 1); // 2021 by default
	category: number = 1;

	energyType: EnergyType[] = [];

	subscribe = new Subscription();

	EnergyCategory = EnergyCategory;
	HelpSubject = HelpSubject;
	categoryTourName = "";

	stepError = false;
	Reason = Reason;

	yearFromQuery: number | null = null;

	@ViewChild("confirmPartialCsv") confirmPartialCsv!: TemplateRef<MatDialog>;

	/**
	 * Initialise the consumption declaration page
	 * Gets the list of energies used in the declaration
	 * Gets the list of declared categories excluded from the declaration
	 * Gets invoices values and pushes them into the consumption table object
	 */
	constructor(
		private router: Router,
		private dialog: MatDialog,
		private functionalDeclarationState: FunctionalDeclarationStateService,
		public declarationState: DeclarationStateService,
		public representativeState: RepresentativeStateService,
		public declarationGroupeState: DeclarationGroupStateService,
		private route: ActivatedRoute,
		private functionalDeclarationService: DeclarationFunctionalService,
		private tourState: TourStateService,
		private activatedRoute: ActivatedRoute,
		private http: HttpClient,
		private snackBar: MatSnackBar,
	) {
		super();
		this.sub(this.route.queryParams, (params) => {
			if (params.year && this.years.indexOf(Number(params.year)) !== -1) {
				this.yearFromQuery = Number(params.year);
			}
		});

		this.sub(this.tourState.start$, () => {
			this.category = 1;
		});

		this.sub(this.activatedRoute.queryParams, (params) => {
			this.stepError = params.error ?? false;

			if (this.stepError) {
				setTimeout(() => {
					const stepErrorDiv = document.getElementById("stepErrorDiv");
					if (stepErrorDiv) {
						stepErrorDiv.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
						stepErrorDiv.classList.add("zoom");
						setTimeout(() => {
							stepErrorDiv.classList.remove("zoom");
						}, 2000);
					}
				});
			}
		});

		this.tourState.initialize([
			{
				anchorId: "consumption-formula",
				content:
					"Afin de calculer votre consommation finale, nous allons soustraire l'ensemble de vos consommations à décompter à vos consommations générales.",
				title: "Saisie de vos données de consommations",
			},
			{
				anchorId: "consumption-general",
				content: "Vous retrouverez dans cet encadré vert l'ensemble de vos données de consommations saisies.",
				title: "Vos consommations générales",
			},
			{
				anchorId: "consumption-general-add-row",
				content:
					"Pour commencer la saisie, vous devez ajouter une ligne dans le tableau. Ces lignes vous permettrons d'organiser vos consommations selon vos surfaces ou vos sous-comptages par exemple.",
				title: "Ajouter une ligne de consommations",
				nextOnAnchorClick: true,
			},
			{
				anchorId: "consumption-add-row-modal-name",
				content: "Donnez un nom à cette ligne permettant de la décrire brièvement.",
				title: "Nommez votre ligne",
				isAsync: true,
			},
			{
				anchorId: "consumption-add-row-modal-energy",
				content:
					"Sélectionnez toutes les énergies pour lesquelles vous voulez saisir des données de consommation pour cette ligne.",
				title: "Sélectionnez les types d'énergies concernées",
				placement: {
					yPosition: "above",
				},
			},
			{
				anchorId: "consumption-add-row-modal-activity",
				content:
					"Pour chacunes de vos énergies, saisissez les dates de début et fin de mise en activité. La date minimale pour la date de début de mise en activité est le 1er janvier 2009, au dela de cette date, les données de consommations ne nous importent pas. Laissez une date de fin d'activité vide si vous utilisez encore cette énergie. Si vous utilisez des combustibles stockable (fioul, bois ...), vous pouvez choisir de saisir par livraisons : une moyenne sera ensuite effectuée afin de correspondre aux attentes de l'ADEME.",
				title: "Paramétrez vos énergies",
			},
			{
				anchorId: "consumption-add-row-modal-save",
				content: "Une fois la sélection terminée, vous pouvez sauvegarder.",
				title: "Sauvegardez",
				nextOnAnchorClick: true,
			},
		]);

		this.tourState.service.stepHide$.subscribe((e) => {
			if (e.step.anchorId === "consumption-add-row-modal-save") {
				// Necessary to prevent loop
				setTimeout(() => {
					this.tourState.end();
					if (this.categoryTourName !== "") {
						setTimeout(() => {
							this.tourState.start([
								{
									anchorId: `consumption-general-add-consumption-${this.categoryTourName}`,
									content:
										"Vous pouvez cliquer soit sur le bouton, soit sur un espace vide afin d'ajouter une donnée de consommation.",
									title: "Ajouter une donnée de consommation",
									nextOnAnchorClick: true,
								},
							]);
						}, 100);
					}
				}, 0);
			}
		});
	}

	startDeductTour() {
		this.tourState.end();
		this.tourState.start([
			{
				anchorId: "consumption-deduct",
				content:
					"Maintenant que vous savez saisir des consommations, vous pouvez reproduire le procédé sur les consommations à déduire. N'oubliez pas de ne pas laissez de trou de consommation au risque de ne pas pouvoir calculer votre année de référence correctement.",
				title: "Répétez cette étape pour les consommations à déduire",
				nextOnAnchorClick: true,
			},
		]);
	}

	init(functionalDeclaration: Immutable<FunctionalDeclaration>) {
		this.functionalDeclaration = cloneFunctionalDeclaration(functionalDeclaration);
		if (this.functionalDeclaration.infos.favoritePeriod === undefined) {
			this.functionalDeclaration.infos.favoritePeriod = 0;
		}
		this.energyType = this.functionalDeclaration.infos.asset.energyType;

		// initialize every categories
		for (const energyCategory of Object.values(EnergyCategory)) {
			if (this.functionalDeclaration.consumptions[energyCategory] === undefined) {
				this.functionalDeclaration.consumptions[energyCategory] = {};
			}
		}

		const multiOccupation = isMultiOccupation(this.functionalDeclaration);
		const favPeriod = functionalDeclaration.infos.favoritePeriod;
		// add the consumption rows for each agreement
		const data: { [p: string]: number } = {};
		if (this.functionalDeclaration.first_year_declaration) {
			const { start } = periodRange.transform(this.functionalDeclaration.first_year_declaration, favPeriod);
			data.start = new Date(start).getTime() / 1000;
		}
		for (const agreementAsk of this.functionalDeclaration.agreement_asks) {
			const agreementCategory = agreementAsk.energy_category ?? EnergyCategory.INDIVIDUAL;

			if (
				agreementAsk.type === "PRM" &&
				this.functionalDeclaration.consumptions[agreementCategory][`elec_${agreementAsk.reference}`] === undefined
			) {
				this.functionalDeclaration.consumptions[agreementCategory][`elec_${agreementAsk.reference}`] = {
					label: `Consommation compteur ${agreementAsk.reference}`,
					values: {},
				};
				this.functionalDeclaration.consumptions[agreementCategory][`elec_${agreementAsk.reference}`].values[
					EnergyType.ELECTRICITY_KWH
				] = new EnergyConsumptions(data);
			}
			if (
				agreementAsk.type === "PCE" &&
				this.functionalDeclaration.consumptions[agreementCategory][`gaz_${agreementAsk.reference}`] === undefined
			) {
				this.functionalDeclaration.consumptions[agreementCategory][`gaz_${agreementAsk.reference}`] = {
					label: `Consommation compteur ${agreementAsk.reference}`,
					values: {},
				};
				this.functionalDeclaration.consumptions[agreementCategory][`gaz_${agreementAsk.reference}`].values[
					EnergyType.GAZ_NAT_NETWORK_KWH
				] = new EnergyConsumptions(data);
			}
		}

		// add a row if none is present
		if (
			Object.keys(this.functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL]).length === 0 &&
			(!multiOccupation ||
				(Object.keys(this.functionalDeclaration.consumptions[EnergyCategory.COMMON]).length === 0 &&
					Object.keys(this.functionalDeclaration.consumptions[EnergyCategory.DISTRIBUTED]).length === 0))
		) {
			const line = multiOccupation ? "individual_consumption" : "total_consumption";
			this.functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL][line] = {
				label: multiOccupation ? "Consommation individuelle" : "Consommation totale",
				values: {},
			};
			for (const energyType of this.energyType) {
				this.functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL][line].values[energyType] =
					new EnergyConsumptions({
						start: minStartPipe.transform(
							this.functionalDeclaration.consumptions,
							energyType,
							multiOccupation,
							this.functionalDeclaration[GreenKeys.KEY_FIRST_YEAR_DECLARATION],
							favPeriod,
						),
						end: maxEndPipe.transform(this.functionalDeclaration.consumptions, energyType, multiOccupation),
						byInvoice: this.DELIVERABLE.includes(energyType),
					});
			}
		}

		// add a row for electric car dock if non exist
		if (
			this.functionalDeclaration.infos.asset.electricCarDock.length > 0 &&
			this.functionalDeclaration.consumptions[EnergyCategory.DEDUCT]["electric_car_dock"] === undefined &&
			this.checkBornesDates(this.functionalDeclaration)
		) {
			this.functionalDeclaration.consumptions[EnergyCategory.DEDUCT]["electric_car_dock"] = {
				label: "Borne(s) IRVE",
				values: {},
			};
			const categoryStartPipe = new CategoryStartPipe();
			this.functionalDeclaration.consumptions[EnergyCategory.DEDUCT]["electric_car_dock"].values[
				EnergyType.ELECTRICITY_KWH
			] = new EnergyConsumptions({
				start: MinDateWithDateStartEntity.getMinDateForNewlyLiableEntity(
					functionalDeclaration,
					categoryStartPipe.transform(this.functionalDeclaration.infos.asset.electricCarDock),
				),
				end: undefined,
			});
		}

		// add lines for exempted categories
		for (const batiment of this.functionalDeclaration.infos.buildingInfos) {
			for (const cat of Object.entries(batiment.ownedSurface.categorySurfaceAreas)) {
				this.buildCategories(cat, this.functionalDeclaration);
			}
		}

		// add rows in usage consumption
		const heatModifierTypeDisplayPipe = new HeatModifierTypeDisplayPipe();

		for (const c of this.functionalDeclaration.infos.asset.categories) {
			for (const hmt of Object.values(HeatModifierType)) {
				if (
					c.temperatureModifiers.includes(hmt) &&
					this.functionalDeclaration.consumptions[EnergyCategory.USAGE][hmt] === undefined
				) {
					this.functionalDeclaration.consumptions[EnergyCategory.USAGE][hmt] = {
						label: heatModifierTypeDisplayPipe.transform(hmt),
						values: {},
					};
					for (const energyType of this.energyType) {
						this.functionalDeclaration.consumptions[EnergyCategory.USAGE][hmt].values[energyType] =
							new EnergyConsumptions({
								start: minStartPipe.transform(
									this.functionalDeclaration.consumptions,
									energyType,
									multiOccupation,
									this.functionalDeclaration[GreenKeys.KEY_FIRST_YEAR_DECLARATION],
									favPeriod,
								),
								end: maxEndPipe.transform(this.functionalDeclaration.consumptions, energyType, multiOccupation),
								byInvoice: this.DELIVERABLE.includes(energyType),
							});
					}
				}
			}
		}
	}

	ngOnInit() {
		// When fd selected change
		const fd$ = this.functionalDeclarationState.get$.pipe(
			switchMap((fd) => (fd ? of(fd) : EMPTY)),
			map(({ value }) => value),
			distinctUntilKeyChanged(GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID),
		);

		this.sub(fd$, (fd: Immutable<FunctionalDeclaration>) => {
			if (this.yearFromQuery) {
				const year = this.yearFromQuery;
				this.yearFromQuery = null;
				const filteredYears = this.years.filter((year) => this.isAccessible(year, fd) && !this.isLocked(year, fd));
				this.category = 0;
				if (filteredYears.indexOf(year) >= 0) {
					this.selectedIndex = this.years.indexOf(year);
					return;
				}
			}
			this.selectedIndex = this.indexOfLastAvailableYear(fd);
		});
		this.subscribe = this.functionalDeclarationState.get$
			.pipe(
				switchMap((functionalDeclaration) => (functionalDeclaration ? of(functionalDeclaration) : EMPTY)),
				map(({ value }) => value),
				switchMap((fd) => {
					const reason = canNotReachStep(fd, CONSO_PAGE);
					if (reason !== null) {
						// HOTFIX
						setTimeout(
							() =>
								this.router.navigate(RouteDealer.assetDeclaration(unwrap(fd.declaration_functional_id)), {
									queryParams: { error: reason },
								}),
							0,
						);
						return EMPTY;
					}

					return of(fd);
				}),
			)
			.subscribe((fd) => this.init(fd));
	}

	ngOnDestroy() {
		this.subscribe.unsubscribe();
	}

	goToList(id: DeclarationId) {
		this.router.navigate(RouteDealer.consoList(id));
	}

	/**
	 * Builds an array of categories based on qualification form declaration
	 * @param cat category or subcategory
	 * @param functionalDeclaration
	 */
	public buildCategories(
		cat: [string, number | Record<string, number>],
		functionalDeclaration: FunctionalDeclaration,
	): void {
		if (cat[1] instanceof Object) {
			Object.entries(cat[1]).forEach((cat) => {
				this.buildCategories(cat, functionalDeclaration);
			});
		} else if (cat[1] > 0) {
			if (functionalDeclaration.consumptions[EnergyCategory.DEDUCT] === undefined) {
				functionalDeclaration.consumptions[EnergyCategory.DEDUCT] = {};
			}
			if (functionalDeclaration.consumptions[EnergyCategory.DEDUCT][cat[0]] === undefined) {
				const categoryKeyToLabelPipe = new CategoryKeyToLabelPipe();
				const minStartPipe = new MinStartForEnergyTypePipe();
				const maxEndPipe = new MaxEndForEnergyTypePipe();
				const multiOccupation = isMultiOccupation(functionalDeclaration);
				const favPeriod = functionalDeclaration[GreenKeys.KEY_INFOS].favoritePeriod;
				functionalDeclaration.consumptions[EnergyCategory.DEDUCT][cat[0]] = {
					label: categoryKeyToLabelPipe.transform(cat[0]),
					values: {},
				};
				this.energyType.forEach(
					(energyType) =>
						(functionalDeclaration.consumptions[EnergyCategory.DEDUCT][cat[0]].values[energyType] =
							new EnergyConsumptions({
								start: minStartPipe.transform(
									functionalDeclaration.consumptions,
									energyType,
									multiOccupation,
									functionalDeclaration[GreenKeys.KEY_FIRST_YEAR_DECLARATION],
									favPeriod,
								),
								end: maxEndPipe.transform(functionalDeclaration.consumptions, energyType, multiOccupation),
								byInvoice: this.DELIVERABLE.includes(energyType),
							})),
				);
			}
		}
	}

	public fireUpdate(functionalDeclaration: FunctionalDeclaration) {
		// sometimes we get an issue while saving, it is called twice, once it is writable, and the other time it is not, so we first check that we are editing the
		// editable one. This seems to be fixed with other things done, but I think it's best to keep it just in case to prevent from throwing error
		if (Object.getOwnPropertyDescriptor(functionalDeclaration, "infos")?.writable) {
			if (allConsumptionsIncomplete(functionalDeclaration)) {
				functionalDeclaration.infos.referenceYear = undefined;
				functionalDeclaration.infos.absoluteObjective = undefined;
				functionalDeclaration.infos.relativeObjective = undefined;
			}
			this.functionalDeclarationService.update(functionalDeclaration);
			this.functionalDeclaration = cloneFunctionalDeclaration(functionalDeclaration);
		}
	}

	public switchYear(year: number) {
		this.category = 0;
		if (this.years.includes(year) && this.isAccessible(year)) {
			this.selectedIndex = this.years.indexOf(year);
		}
	}

	public openAddRowModal(
		row: { label: string; values: { [key: string]: EnergyConsumptions } } | undefined,
		category: EnergyCategory,
		functionalDeclaration: FunctionalDeclaration,
		rowName?: string,
	): void {
		let adding = false;
		let index: string | undefined = undefined;
		const consumptionTable = functionalDeclaration.consumptions[category];
		if (row === undefined) {
			row = { label: "", values: {} };
			adding = true;
		} else {
			index = Object.keys(consumptionTable).find((k) => row === consumptionTable[k]);
		}

		this.dialog
			.open(AddRowModalComponent, {
				data: {
					functionalDeclaration,
					rowName,
					row,
					adding,
					energyType: this.energyType,
					isDeduct: category === EnergyCategory.DEDUCT,
					sharedBuilding: isMultiOccupation(functionalDeclaration),
					energyCategory: category,
				},
				panelClass: "p-0",
			})
			.afterClosed()
			.subscribe((consumptionRow: { label: string; values: { [key: string]: EnergyConsumptions } } | undefined) => {
				if (index !== undefined) {
					if (consumptionRow === null) {
						delete consumptionTable[index];
					}
					if (consumptionRow) {
						consumptionTable[index] = consumptionRow;
					}
				} else {
					if (
						consumptionRow !== null &&
						consumptionRow !== undefined &&
						consumptionRow.label !== "" &&
						Object.keys(consumptionRow.values).length !== 0
					) {
						let count = 0;
						let label = "";
						do {
							label = toSnakeCase(consumptionRow.label) + (count === 0 ? "" : count);
							count++;
						} while (consumptionTable[label] !== undefined);
						this.categoryTourName = label + "-" + category;
						consumptionTable[label] = consumptionRow;
					}
				}
				const copy: {
					[key: string]: { label: string; values: { [energy: string]: EnergyConsumptions } };
				} = {};
				Object.keys(consumptionTable).forEach((key) => {
					copy[key] = { label: consumptionTable[key].label, values: {} };
					Object.keys(consumptionTable[key].values).forEach((subKey) => {
						copy[key].values[subKey] = new EnergyConsumptions(consumptionTable[key].values[subKey]);
					});
				});
				functionalDeclaration.consumptions[category] = copy;
				this.updateInvoice(functionalDeclaration);
				setTimeout(() => {
					this.init(functionalDeclaration);
				}, 500);
			});
	}

	public updatePeriod(functionalDeclaration: FunctionalDeclaration) {
		this.updateConsoRow(functionalDeclaration);
		this.updateInvoice(functionalDeclaration);
		this.fireUpdate(functionalDeclaration);
	}

	public updateInvoice(functionalDeclaration: FunctionalDeclaration) {
		const sumInvoicePerYear = new SumInvoicePerYearPipe();
		const yearForDateAndPeriodPipe = new YearForDateAndPeriodPipe();
		Object.keys(functionalDeclaration.consumptions).forEach((cat) => {
			Object.keys(functionalDeclaration.consumptions[cat]).forEach((subCat) => {
				Object.keys(functionalDeclaration.consumptions[cat][subCat].values).forEach((energy) => {
					const energyConsumption = functionalDeclaration.consumptions[cat][subCat].values[energy];
					if (energyConsumption.byInvoice) {
						energyConsumption.values = [];
						const dataPerYear = sumInvoicePerYear.transform(
							energyConsumption.invoiceData,
							functionalDeclaration.infos.favoritePeriod,
						);
						const periodStart = yearForDateAndPeriodPipe.transform(
							energyConsumption.start,
							functionalDeclaration.infos.favoritePeriod,
						);
						const consumptionEnd = energyConsumption.end ?? new Date().getTime() / 1000;
						const consumptionEndDate = new Date(consumptionEnd * 1000);
						let consumptionEndYear = consumptionEndDate.getFullYear();

						if (consumptionEndDate.getMonth() === 0 && consumptionEndDate.getDate() === 1) {
							consumptionEndYear--;
						}

						const periodEnd = consumptionEnd
							? yearForDateAndPeriodPipe.transform(consumptionEnd, functionalDeclaration.infos.favoritePeriod)
							: new Date().getFullYear();
						const yearStart = Number(new Date(energyConsumption.start * 1000).getFullYear());
						for (let i = periodStart; i <= periodEnd; i++) {
							let sum = 0;
							const max = Math.min(consumptionEndYear, i + (energyConsumption.yearAverage ?? 4) - 1);
							const stop = Math.max(max - (energyConsumption.yearAverage ?? 4) + 1, yearStart);

							for (let j = max; j >= stop; j--) {
								sum += dataPerYear[j] ?? 0;
							}
							if (yearStart > i || i > max) {
								sum = 0;
							}
							sum = sum / (max - stop + 1);
							const period = periodRange.transform(i, functionalDeclaration.infos.favoritePeriod);
							const start = dateToTimestamp.transform(period.start);
							const end = dateToTimestamp.transform(period.end);
							if (energyConsumption.hasRepartitionKey) {
								energyConsumption.repartitionKeys.forEach((repartitionKey) => {
									if ((repartitionKey.end && start > repartitionKey.end) || end < repartitionKey.start) {
										return;
									}
									const consumptionStart = Math.max(start, repartitionKey.start);
									const consumptionEnd = repartitionKey.end ? Math.min(end, repartitionKey.end) : end;
									const value = (sum * (consumptionEnd - consumptionStart)) / (end - start);
									energyConsumption.values.push(
										new ConsumptionEntry({
											date_start: consumptionStart,
											date_end: consumptionEnd,
											value: value ?? 0, // In case of NaN (division by 0)
											source: "delivery",
											hasRepartitionKey: true,
											partUmpteenth: repartitionKey.partUmpteenth,
											totalUmpteenth: repartitionKey.totalUmpteenth,
										}),
									);
								});
							} else {
								energyConsumption.values.push(
									new ConsumptionEntry({
										date_start: start,
										date_end: end,
										value: sum ?? 0,
										source: "delivery",
									}),
								);
							}
						}
					}
				});
			});
		});
		this.fireUpdate(functionalDeclaration);
	}

	getConsumptionCsv(
		functionalDeclaration: FunctionalDeclaration,
		annualDataWillBePartial: boolean,
		initialDataWillBePartial: boolean,
	) {
		if (annualDataWillBePartial || initialDataWillBePartial) {
			this.dialog.open(this.confirmPartialCsv, {
				data: {
					annualDataWillBePartial,
					initialDataWillBePartial,
				},
			});
		} else {
			this.downloadConsumptionCsv(functionalDeclaration);
		}
	}

	modalPartialCsvResult(download: boolean, fd: FunctionalDeclaration) {
		this.dialog.closeAll();
		if (download) {
			this.downloadConsumptionCsv(fd);
		}
	}

	downloadConsumptionCsv(functionalDeclaration: FunctionalDeclaration) {
		this.functionalDeclarationService
			.getConsumptionCsv$(unwrap(functionalDeclaration[GreenKeys.KEY_DECLARATION_FUNCTIONAL_ID]))
			.subscribe((content) => {
				const link = document.createElement("a");
				link.download = "consumptions.csv";
				const url = URL.createObjectURL(new Blob([content], { type: "text/csv;charset=utf-8" }));
				link.href = url;
				link.click();
				URL.revokeObjectURL(url);
			});
	}

	openHistoryModal() {
		dialogOpen(this.dialog, DialogComponent, { subject: HelpSubject.LongHistory, argument: "" }, { panelClass: "p-0" });
	}

	openProofFilesModal(functionalDeclaration: FunctionalDeclaration) {
		dialogOpen(
			this.dialog,
			ProofFilesModalsComponent,
			{
				files: functionalDeclaration.files,
				functionalDeclarationId: unwrap(functionalDeclaration.declaration_functional_id),
				http: this.http,
			},
			{
				disableClose: true,
			},
		)
			.afterClosed()
			.pipe(
				map((files) => {
					if (!files) {
						// better to crash the app in this case
						throw new Error("Impossible: the proof files modal returned undefined");
					}

					return files;
				}),
				map((files) =>
					produce(functionalDeclaration, (draft) => {
						draft.files = castDraft(files);
					}),
				),
				switchMap((updatedEntity) =>
					this.functionalDeclarationState.update$(
						// don't need to use fireUpdate
						updatedEntity,
					),
				),
			)
			.subscribe();
	}

	isLocked(
		year: number,
		functionalDeclaration: Immutable<FunctionalDeclaration> | null = this.functionalDeclaration,
	): boolean {
		return (
			year <
				(functionalDeclaration
					? functionalDeclaration.first_year_declaration ?? Declaration.MINIMUM_YEAR
					: Declaration.MINIMUM_YEAR) ||
			(year <= Declaration.MAXIMUM_YEAR_INITIAL_DECLARATION && !functionalDeclaration?.is_token_used) ||
			(year >= Declaration.ANNUAL_DECLARATION_STARTING_YEAR && !functionalDeclaration?.years_token_used.includes(year))
		);
	}

	isAccessible(year: number, fd: Nullable<Immutable<FunctionalDeclaration>> = this.functionalDeclaration): boolean {
		return !fd || !fd.first_year_declaration || year >= fd.first_year_declaration;
	}

	onTabChanged(
		index: number,
		fd: FunctionalDeclaration,
		representative: Immutable<Lazy<Representative>> | null,
		declarationGroup: Immutable<Lazy<DeclarationGroup>> | null,
	): void {
		const year = this.years[index];

		if (this.isLocked(year)) {
			if (fd.first_year_declaration && year < fd.first_year_declaration) {
				const availableYears = this.availableYears;
				this.selectedIndex = this.years.indexOf(availableYears[availableYears.length - 1]);
				return;
			}
			if (year <= Declaration.MAXIMUM_YEAR_INITIAL_DECLARATION) {
				this.consumeInitialToken(fd, representative, declarationGroup);
			} else {
				this.unlockAnnualYear(year, fd, representative, declarationGroup);
			}
		}
	}

	private consumeInitialToken(
		fd: FunctionalDeclaration,
		representative: Immutable<Lazy<Representative>> | null,
		declarationGroup: Immutable<Lazy<DeclarationGroup>> | null,
	) {
		const dialog = dialogOpen(
			this.dialog,
			ConsumeTokenDialogComponent,
			{
				functionalService: this.functionalDeclarationService,
				functionalDeclaration: fd,
				representative: representative,
				declarationGroup: declarationGroup,
				messageType: ConsumeTokenMessageType.LOCKED_CONSUMPTION,
			},
			{ panelClass: "p-0" },
		);
		dialog
			.afterClosed()
			.pipe(
				switchMap((output) => {
					if (output) {
						switch (output.type) {
							case "updated":
								return of(output.entity).pipe(
									switchMap((entityWithTokenUsed) => {
										if (this.declarationGroupeState.getId()) {
											return this.declarationGroupeState.get$.pipe(
												first(),
												switchMap((group) => (group ? of(group.value) : EMPTY)),
												map((group) =>
													produce(group, (draft) => {
														draft.used_tokens++;
													}),
												),
												switchMap((group) => this.declarationGroupeState.update$(group)),
												map(() => entityWithTokenUsed),
											);
										}
										return of(entityWithTokenUsed);
									}),
								);
							case "cant buy":
								this.snackBar.open(
									"Vous n'avez pas assez de déclarations pour aller jusqu'à cette étape. Pour continuer veuillez contacter votre référent",
									"OK",
									{ verticalPosition: "top" },
								);
								break;
							case "cancel":
								break;
						}
					}
					this.selectedIndex = this.years.indexOf(this.availableYears[0]);
					return EMPTY;
				}),
				switchMap((entityWithToken) => this.functionalDeclarationState.update$(entityWithToken)),
			)
			.subscribe();
	}

	private unlockAnnualYear(
		year: number,
		fd: FunctionalDeclaration,
		representative: Immutable<Lazy<Representative>> | null,
		declarationGroup: Immutable<Lazy<DeclarationGroup>> | null,
	) {
		const dialog = dialogOpen(
			this.dialog,
			ConsumeAnnualTokenModalComponent,
			{
				functionalService: this.functionalDeclarationService,
				groupService: this.declarationGroupeState,
				functionalDeclaration: fd,
				representative: representative,
				declarationGroup: declarationGroup,
				year,
			},
			{ panelClass: "p-0" },
		);
		dialog
			.afterClosed()
			.pipe(
				switchMap((output) => {
					if (output) {
						switch (output.type) {
							case "updated":
								if (declarationGroup) {
									return this.declarationGroupeState.get$.pipe(
										first(),
										switchMap((group) => (group ? of(group.value) : EMPTY)),
										map((group) =>
											produce(group, (draft) => {
												draft.used_annual_tokens++;
											}),
										),
										switchMap((group) => this.declarationGroupeState.update$(group)),
										map(() => output.entity),
									);
								}
								break;
							case "cant buy":
								this.snackBar.open(
									"Vous n'avez pas assez de crédits annuels pour débloquer une année. Pour continuer, veuillez contacter votre référent",
									"OK",
									{ verticalPosition: "top" },
								);
								break;
							case "no permissions":
								this.snackBar.open(
									"Vous n'avez pas les droits pour débloquer une année. Pour continuer, veuillez contacter votre référent",
									"OK",
									{
										verticalPosition: "top",
									},
								);
								break;
							case "cancel":
								break;
						}
					}
					const availableYears = this.availableYears.filter((availableYear) => availableYear < year);
					this.selectedIndex = this.years.indexOf(availableYears[availableYears.length - 1]);
					return EMPTY;
				}),
				switchMap((entityWithAnnualYearUnlocked) =>
					this.functionalDeclarationState.update$(entityWithAnnualYearUnlocked),
				),
			)
			.subscribe();
	}

	categoryNotEmpty(...categs: string[]): boolean {
		if (this.functionalDeclaration) {
			for (const categ of categs) {
				if (Object.keys(this.functionalDeclaration.consumptions[categ]).length > 0) return true;
			}
		}
		return false;
	}

	indexOfLastAvailableYear(fd: Immutable<FunctionalDeclaration>) {
		const availableYears = this.years.filter((year) => this.isAccessible(year, fd) && !this.isLocked(year, fd));
		return this.years.indexOf(availableYears[availableYears.length - 1]);
	}

	/**
	 * Compare the start of the year to the start of the period
	 * @param year
	 * @return the difference in years
	 */
	adaptToPeriod(year: number): number {
		const defaultTime = new Date(year + "-01-01");
		const { start } = periodRange.transform(
			year,
			this.functionalDeclaration ? this.functionalDeclaration.infos.favoritePeriod : 0,
		);
		const startPeriod = new Date(start);
		const timeDiff = (startPeriod.getTime() - defaultTime.getTime()) / 1000;
		return timeDiff / (60 * 60 * 24) / 365.25;
	}

	get availableYears(): number[] {
		return this.years.filter((year) => this.isAccessible(year) && !this.isLocked(year));
	}

	private updateConsoRow(functionalDeclaration: FunctionalDeclaration) {
		if (!functionalDeclaration.first_year_declaration) {
			return;
		}

		const { start } = periodRange.transform(
			functionalDeclaration.first_year_declaration,
			functionalDeclaration.infos.favoritePeriod,
		);
		const newStart = dateToTimestamp.transform(start);

		for (const consoRow of Object.values(functionalDeclaration[GreenKeys.KEY_CONSUMPTIONS])) {
			for (const energyEntry of Object.values(consoRow)) {
				for (const energyConsumption of Object.values(energyEntry.values)) {
					if (energyConsumption.start < newStart) {
						energyConsumption.start = newStart;
					}
				}
			}
		}

		this.fireUpdate(functionalDeclaration);
	}

	protected readonly KEY_FIRST_YEAR_DECLARATION = GreenKeys.KEY_FIRST_YEAR_DECLARATION;

	private checkBornesDates(functionalDeclaration: Immutable<FunctionalDeclaration>): boolean {
		if (!functionalDeclaration.first_year_declaration) {
			return true;
		}

		let max: number | undefined = -Infinity;

		functionalDeclaration.infos.asset.electricCarDock.forEach((entry) => {
			max = max === undefined || entry.end === undefined ? undefined : Math.max(entry.end, max);
		});

		const periodRange = new PeriodRangePipe();
		const { start } = periodRange.transform(
			functionalDeclaration.first_year_declaration,
			functionalDeclaration.infos.favoritePeriod,
		);
		const startTimestamp = dateToTimestamp.transform(start);

		return !max || max > startTimestamp;
	}

	protected readonly Declaration = Declaration;
	protected readonly ANNUAL_DECLARATION_STARTING_YEAR = ANNUAL_DECLARATION_STARTING_YEAR;
}
