'use strict';

var getPolyfill = require('./polyfill');
var define = require('define-properties');
module.exports = function shimObjectIs() {
  var polyfill = getPolyfill();
  define(Object, {
    is: polyfill
  }, {
    is: function testObjectIs() {
      return Object.is !== polyfill;
    }
  });
  return polyfill;
};