<ng-container *ngIf="consumptionTable !== undefined">
	<th class="table-label primary" (click)="this.editRow.emit(consumptionTable)" [rowSpan]="rowspanLength">
		{{ consumptionTable.label }}
	</th>
	<tr *ngFor="let energyString of consumptionTable.values | objectKeys">
		<ng-container *ngFor="let energy of [energyString | energyTypeFromString]">
			<ng-container *ngFor="let energyStart of [this.start | max: consumptionTable.values[energy.valueOf()].start]">
				<ng-container
					*ngFor="let energyEnd of [this.end | min: consumptionTable.values[energy.valueOf()].end ?? this.end]"
				>
					<td>{{ energy | energyTypeTitle }}</td>
					<td [colSpan]="colSpan" (click)="openAddValueModal(undefined, energy)" class="row">
						<div
							*ngIf="agreement && (!agreement.short_data_received || !agreement.year_data_received)"
							class="overlay d-flex-col"
							(click)="$event.stopPropagation()"
						>
							<span>
								<ng-container *ngIf="agreement.is_email_sent && agreement.is_accepted">
									En attente de réception des données de consommations de la part du distributeur.
								</ng-container>
								<ng-container *ngIf="agreement.is_email_sent && !agreement.is_accepted">
									En attente de l'autorisation du titulaire.
								</ng-container>
								<ng-container *ngIf="!agreement.is_email_sent && !agreement.is_accepted">
									En attente de l'envoi de la demande d'autorisation.
								</ng-container>
							</span>
							<button mat-flat-button class="btn-danger" (click)="skipWaitData(); $event.stopPropagation()">
								Continuer la saisie manuellement
							</button>
						</div>
						<ng-container
							*ngFor="
								let startActivityPercentage of [
									consumptionTable.values[energy.valueOf()].start
										| min: this.end
										| timestampPercentage: this.start : this.end
								]
							"
						>
							<ng-container
								*ngFor="
									let endActivityPercentage of [
										consumptionTable.values[energy.valueOf()].end ?? this.end
											| min: this.end
											| timestampPercentage: this.start : this.end
									]
								"
							>
								<div
									class="activity activity-{{ colorTimeline }} activity-start"
									[style]="{ width: startActivityPercentage + '%' }"
									(click)="$event.stopPropagation()"
								></div>
								<div
									class="activity activity-{{ colorTimeline }} activity-end"
									[style]="{
										width: 100 - endActivityPercentage + '%',
										left: endActivityPercentage + '%'
									}"
									(click)="$event.stopPropagation()"
								></div>
							</ng-container>
						</ng-container>

						<ul class="timeline timeline-{{ colorTimeline }}">
							<div *ngIf="consumptionTable.values[energy.valueOf()] !== undefined">
								<ng-container *ngIf="!byYear">
									<div
										class="d-flex"
										style="
											position: absolute;
											left: calc(50% - 170px);
											top: calc(50% - 18px + 7.5px);
											width: 340px;
											justify-content: center;
										"
										*ngIf="
											(energy | noValueForYear: this.consumptionTable.values[energy.valueOf()].values : start : end) &&
											!(agreement && (!agreement.is_treated || !agreement.year_data_received))
										"
									>
										<ng-container
											*ngFor="
												let label of [
													this.consumptionTable.values[energy.valueOf()].byInvoice
														? 'Ajouter des livraisons'
														: colorTimeline === 'green'
															? 'Ajouter des valeurs de consommation'
															: colorTimeline === 'red'
																? 'Ajouter des valeurs de consommation à déduire'
																: 'Ajouter des valeurs de consommation par usage'
												]
											"
										>
											<button class="add-value-button">
												<span class="plus-icon">+</span>
												<span class="hover-text">{{ label }}</span>
											</button>
										</ng-container>
									</div>
									<div
										*ngFor="let value of consumptionTable.values[energy.valueOf()].values; let index = index"
										(click)="openAddValueModal(value, energy); $event.stopPropagation()"
									>
										<ng-container
											*ngFor="let displayableValue of [value | displayableConsumptionEntry: energyStart : energyEnd]"
										>
											<ng-container *ngIf="displayableValue && displayableValue.date_start < displayableValue.date_end">
												<ng-container
													*ngFor="
														let startPercent of [
															displayableValue.date_start | timestampPercentage: this.start : this.end
														]
													"
												>
													<ng-container
														*ngFor="
															let endPercent of [displayableValue.date_end | timestampPercentage: this.start : this.end]
														"
													>
														<div class="lidiv" [ngStyle]="{ left: startPercent + '%' }">
															<span
																class="divider-{{ colorTimeline }}"
																[ngStyle]="{
																	transform: 'scaleX(' + (endPercent - startPercent) + '%)'
																}"
															></span>
															<li [matTooltip]="displayableValue.date_start | timestampToDate: 'dd/MM/yyyy'"></li>
														</div>
														<div
															class="lidiv"
															[ngStyle]="{ left: startPercent + (endPercent - startPercent) / 2.5 + '%' }"
														>
															<p
																style="cursor: pointer"
																*ngFor="
																	let value of [displayableValue | correctedValue | energyTypeValueDisplay: energy]
																"
															>
																{{ value | number: (value < 100 ? "1.0-1" : "1.0-0") }}
															</p>
														</div>
														<div
															class="lidiv"
															[ngStyle]="{ left: endPercent + '%' }"
															(click)="$event.stopPropagation()"
														>
															<li [matTooltip]="displayableValue.date_end | timestampToDate: 'dd/MM/yyyy'"></li>
														</div>
													</ng-container>
												</ng-container>
											</ng-container>
										</ng-container>
									</div>
								</ng-container>
								<ng-container *ngIf="byYear">
									<div
										class="d-flex"
										style="
											position: absolute;
											left: calc(50% - 170px);
											top: calc(50% - 18px + 7.5px);
											width: 340px;
											justify-content: center;
										"
										*ngIf="
											consumptionTable.values[energy.valueOf()].values.length === 0 &&
											!(agreement && (!agreement.is_treated || !agreement.year_data_received))
										"
									>
										<ng-container
											*ngFor="
												let label of [
													this.consumptionTable.values[energy.valueOf()].byInvoice
														? 'Ajouter des livraisons'
														: colorTimeline === 'green'
															? 'Ajouter des valeurs de consommation'
															: colorTimeline === 'red'
																? 'Ajouter des valeurs de consommation à déduire'
																: 'Ajouter des valeurs de consommation par usage'
												]
											"
										>
											<button class="add-value-button">
												<span class="plus-icon">+</span>
												<span class="hover-text">{{ label }}</span>
											</button>
										</ng-container>
									</div>
									<div
										*ngFor="
											let entry of consumptionTable.values[energy.valueOf()].values
												| consumptionEntryByYear: energyStart : energyEnd : favoritePeriod
										"
										(click)="
											consumptionTable.values[energy.valueOf()].byInvoice
												? openAddValueModal(undefined, energy)
												: goToYear(entry.year);
											$event.stopPropagation()
										"
									>
										<ng-container *ngIf="entry.year | periodRange: favoritePeriod as period">
											<ng-container
												*ngFor="
													let startPercent of [entry.value.date_start | timestampPercentage: this.start : this.end]
												"
											>
												<ng-container
													*ngFor="let endPercent of [entry.value.date_end | timestampPercentage: this.start : this.end]"
												>
													<div [ngClass]="{ blurred: entry.year | apply: isLocked }">
														<div class="lidiv" [ngStyle]="{ left: startPercent + '%' }">
															<span
																[class]="
																	(consumptionTable.values[energy.valueOf()]
																		| hasHoleInConsumptionRow
																			: (period.start | dateToTimestamp)
																			: (period.end | dateToTimestamp)) && !(entry.year | apply: isLocked)
																		? 'divider-warning'
																		: 'divider-' + colorTimeline
																"
																[ngStyle]="{
																	transform: 'scaleX(' + (endPercent - startPercent) + '%)'
																}"
															></span>
															<li [matTooltip]="entry.value.date_start | timestampToDate"></li>
														</div>
														<div
															class="lidiv"
															[ngStyle]="{ left: startPercent + (endPercent - startPercent) / 2.5 + '%' }"
														>
															<p
																style="cursor: pointer"
																*ngFor="let value of [entry.value | correctedValue | energyTypeValueDisplay: energy]"
															>
																{{
																	!(entry.year | apply: isLocked)
																		? (value | number: (value < 100 ? "1.0-1" : "1.0-0"))
																		: "0000"
																}}
															</p>
														</div>
														<div class="lidiv" [ngStyle]="{ left: endPercent + '%' }">
															<li [matTooltip]="entry.value.date_end | timestampToDate"></li>
														</div>
													</div>
												</ng-container>
											</ng-container>
										</ng-container>
									</div>
								</ng-container>
							</div>
						</ul>
					</td>
				</ng-container>
			</ng-container>
		</ng-container>
	</tr>
</ng-container>
