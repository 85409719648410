<ng-container *ngIf="functionalDeclaration as functionalDeclaration">
	<div class="d-flex mb-2" style="justify-content: space-between">
		<div>
			<span class="d-inline-flex" style="align-items: center">
				<strong> Période préférentielle </strong>
				<app-help [subject]="HelpSubject.FavoritePeriod"></app-help>
			</span>
			<ng-select
				[clearable]="false"
				[(ngModel)]="functionalDeclaration.infos.favoritePeriod"
				(ngModelChange)="updatePeriod(functionalDeclaration)"
			>
				<ng-option *ngFor="let period of PERIOD_LIST; index as i" [value]="i">{{ period }}</ng-option>
			</ng-select>
		</div>
		<div class="d-inline-flex gap-1" style="align-items: start">
			<button mat-button class="btn-primary-light" (click)="openHistoryModal()">Obtenir l'historique long GRDF</button>
			<ng-container *ngFor="let annualDataWillBePartial of [functionalDeclaration | csvDataWillBePartial: true]">
				<ng-container *ngFor="let initialDataWillBePartial of [functionalDeclaration | csvDataWillBePartial: false]">
					<button
						mat-button
						class="btn-primary"
						(click)="getConsumptionCsv(functionalDeclaration, annualDataWillBePartial, initialDataWillBePartial)"
						mat-raised-button
						#tooltip="matTooltip"
						matTooltip="Les données téléchargées ne seront pas complètes"
						matTooltipPosition="below"
						[matTooltipDisabled]="!annualDataWillBePartial && !initialDataWillBePartial"
					>
						<mat-icon *ngIf="!annualDataWillBePartial && !initialDataWillBePartial">download</mat-icon>
						<mat-icon *ngIf="annualDataWillBePartial || initialDataWillBePartial">warning</mat-icon>
						Télécharger mes données de consommation
					</button>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<span class="d-inline-flex" style="align-items: center">
		<button class="btn-primary d-inline-flex gap-2" (click)="openProofFilesModal(functionalDeclaration)">
			<mat-icon>cloud_upload</mat-icon>
			Gérer mes fichiers justificatifs
		</button>
		<app-help [subject]="HelpSubject.ProofFiles"></app-help>
	</span>
	<mat-tab-group
		class="custom-tabs"
		mat-align-tabs="start"
		xmlns="http://www.w3.org/1999/html"
		[(selectedIndex)]="category"
		[mat-stretch-tabs]="false"
		disableRipple="true"
	>
		<mat-tab label="Détail par année">
			<div *ngFor="let representative of [representativeState.get$ | async]">
				<div *ngFor="let declarationGroup of [declarationGroupeState.get$ | async]">
					<mat-tab-group
						disableRipple="true"
						[mat-stretch-tabs]="false"
						class="custom-sub-tabs"
						mat-align-tabs="start"
						[(selectedIndex)]="selectedIndex"
						(selectedTabChange)="
							onTabChanged(
								$event.index,
								functionalDeclaration,
								representative ? representative.value : null,
								declarationGroup ? declarationGroup.value : null
							)
						"
					>
						<mat-tab
							*ngFor="let year of years"
							[disabled]="year < (functionalDeclaration[KEY_FIRST_YEAR_DECLARATION] ?? 2009)"
						>
							<ng-template mat-tab-label>
								<div
									[ngClass]="{
										locked: ([functionalDeclaration] | lockedFunctionalDeclarationsForYear: year).length > 0,
										disabled: year < (functionalDeclaration[KEY_FIRST_YEAR_DECLARATION] ?? 2009)
									}"
								>
									{{ year }}
									<mat-icon
										*ngIf="([functionalDeclaration] | lockedFunctionalDeclarationsForYear: year).length > 0"
										class="icon-lock"
										color="warn"
										>lock</mat-icon
									>
								</div>
							</ng-template>
							<ng-container
								*ngIf="
									category === 0 &&
									selectedIndex === year - years[0] &&
									([functionalDeclaration] | lockedFunctionalDeclarationsForYear: year).length === 0
								"
							>
								<div style="padding: 15px">
									Ne renseignez pas les consommations à décompter si jamais elles sont déjà déduites des consommations
									générales.<br />
									Votre consommation générale sera reconstituée à partir des consommations générales et de celles à
									décompter: elle correspond à votre consommation tertiaire, de laquelle sont déduites les consommations
									des cas particuliers.<br />
									Si vous n'avez aucune consommation sur une ligne de consommations (réparties ou communes par exemple),
									il est nécessaire d'indiquer "0" sur celle-ci. Dans le cas contraire, les consommations seront
									considérées comme "manquantes" et l'année ne pourra être sélectionnée comme année de référence.

									<div class="mt-3">
										<span
											class="danger link"
											matTooltip="Une fausse déclaration est passible des sanctions prévues au R. 441-6 du code pénal (deux ans d'emprisonnement et de 30 000 euros d'amende pour le déclarant). &quot;Des contrôles aléatoires pourront ainsi être menés chaque année pour vérifier l’exactitude des renseignements.&quot;"
										>
											Sanctions
										</span>
									</div>

									<div style="font-weight: 700; padding: 10px 0; width: 100%; text-align: center">
										Consommation finale = <span class="brut-consumption">Consommations générales</span> -
										<span class="correction">Consommations à décompter</span>
									</div>
								</div>
								<ng-container
									*ngFor="
										let startTimestamp of [
											(year | periodRange: functionalDeclaration.infos.favoritePeriod).start | dateToTimestamp
										]
									"
								>
									<ng-container
										*ngFor="
											let endTimestamp of [
												(year | periodRange: functionalDeclaration.infos.favoritePeriod).end | dateToTimestamp
											]
										"
									>
										<mat-accordion multi>
											<mat-expansion-panel [expanded]="true" class="custom-accordion-green">
												<mat-expansion-panel-header>
													<mat-panel-title style="flex-grow: 1"> Consommations générales </mat-panel-title>
												</mat-expansion-panel-header>
												<ng-container *ngIf="functionalDeclaration | isMultiOccupation; else elseBlock">
													<h2>
														Consommation individuelle
														<app-help [subject]="HelpSubject.IndividualConso"></app-help>
													</h2>
													<table class="custom-table-consumption">
														<tr>
															<th class="corner"></th>
															<th>Énergie</th>
															<th *ngFor="let header of HEADERS_MONTHLY; index as i">
																{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
																{{
																	(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
																		((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
																}}
															</th>
														</tr>
														<tbody
															app-consumption-table-row
															[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
															[start]="startTimestamp"
															[end]="endTimestamp"
															[colorTimeline]="'green'"
															[consumptionTable]="
																functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL][category]
															"
															class="bigline"
															*ngFor="
																let category of functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL]
																	| objectKeys
															"
															(update)="fireUpdate(functionalDeclaration)"
															(updateInvoice)="updateInvoice(functionalDeclaration)"
															(editRow)="
																openAddRowModal($event, EnergyCategory.INDIVIDUAL, functionalDeclaration, category)
															"
															[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
															[category]="EnergyCategory.INDIVIDUAL"
															[unlockedYears]="functionalDeclaration.years_token_used"
															[isTokenConsumed]="functionalDeclaration.is_token_used"
														></tbody>
													</table>
													<button
														mat-flat-button
														class="btn-primary-light mt-2"
														(click)="openAddRowModal(undefined, EnergyCategory.INDIVIDUAL, functionalDeclaration)"
													>
														<mat-icon>add</mat-icon>
														Ajouter une ligne
													</button>
													<h2>
														Consommation commune
														<app-help [subject]="HelpSubject.SharedConso"></app-help>
													</h2>
													<table class="custom-table-consumption">
														<tr>
															<th class="corner"></th>
															<th>Énergie</th>
															<th *ngFor="let header of HEADERS_MONTHLY; index as i">
																{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
																{{
																	(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
																		((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
																}}
															</th>
														</tr>
														<tbody
															app-consumption-table-row
															[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
															[start]="startTimestamp"
															[end]="endTimestamp"
															[colorTimeline]="'green'"
															[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.COMMON][category]"
															class="bigline"
															*ngFor="
																let category of functionalDeclaration.consumptions[EnergyCategory.COMMON] | objectKeys
															"
															(update)="fireUpdate(functionalDeclaration)"
															(updateInvoice)="updateInvoice(functionalDeclaration)"
															(editRow)="
																openAddRowModal($event, EnergyCategory.COMMON, functionalDeclaration, category)
															"
															[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
															[category]="EnergyCategory.COMMON"
															[unlockedYears]="functionalDeclaration.years_token_used"
															[isTokenConsumed]="functionalDeclaration.is_token_used"
														></tbody>
													</table>
													<button
														mat-flat-button
														class="btn-primary-light mt-2"
														(click)="openAddRowModal(undefined, EnergyCategory.COMMON, functionalDeclaration)"
													>
														<mat-icon>add</mat-icon>
														Ajouter une ligne
													</button>
													<h2>
														Consommation répartie
														<app-help [subject]="HelpSubject.DistributedConso"></app-help>
													</h2>
													<table class="custom-table-consumption">
														<tr>
															<th class="corner"></th>
															<th>Énergie</th>
															<th *ngFor="let header of HEADERS_MONTHLY; index as i">
																{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
																{{
																	(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
																		((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
																}}
															</th>
														</tr>
														<tbody
															app-consumption-table-row
															[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
															[start]="startTimestamp"
															[end]="endTimestamp"
															[colorTimeline]="'green'"
															[consumptionTable]="
																functionalDeclaration.consumptions[EnergyCategory.DISTRIBUTED][category]
															"
															class="bigline"
															*ngFor="
																let category of functionalDeclaration.consumptions[EnergyCategory.DISTRIBUTED]
																	| objectKeys
															"
															(update)="fireUpdate(functionalDeclaration)"
															(updateInvoice)="updateInvoice(functionalDeclaration)"
															(editRow)="
																openAddRowModal($event, EnergyCategory.DISTRIBUTED, functionalDeclaration, category)
															"
															[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
															[category]="EnergyCategory.DISTRIBUTED"
															[unlockedYears]="functionalDeclaration.years_token_used"
															[isTokenConsumed]="functionalDeclaration.is_token_used"
														></tbody>
													</table>
													<button
														mat-flat-button
														class="btn-primary-light mt-2"
														(click)="openAddRowModal(undefined, EnergyCategory.DISTRIBUTED, functionalDeclaration)"
													>
														<mat-icon>add</mat-icon>
														Ajouter une ligne
													</button>
												</ng-container>
												<ng-template #elseBlock>
													<table class="custom-table-consumption">
														<tr>
															<th class="corner"></th>
															<th>Énergie</th>
															<th *ngFor="let header of HEADERS_MONTHLY; index as i">
																{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
																{{
																	(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
																		((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
																}}
															</th>
														</tr>
														<tbody
															app-consumption-table-row
															[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
															[start]="startTimestamp"
															[end]="endTimestamp"
															[colorTimeline]="'green'"
															[consumptionTable]="
																functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL][category]
															"
															class="bigline"
															*ngFor="
																let category of functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL]
																	| objectKeys
															"
															(update)="fireUpdate(functionalDeclaration)"
															(updateInvoice)="updateInvoice(functionalDeclaration)"
															(editRow)="
																openAddRowModal($event, EnergyCategory.INDIVIDUAL, functionalDeclaration, category)
															"
															[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
															[category]="EnergyCategory.INDIVIDUAL"
															[unlockedYears]="functionalDeclaration.years_token_used"
															[isTokenConsumed]="functionalDeclaration.is_token_used"
														></tbody>
													</table>
													<button
														mat-flat-button
														class="btn-primary-light mt-2"
														(click)="openAddRowModal(undefined, EnergyCategory.INDIVIDUAL, functionalDeclaration)"
													>
														<mat-icon>add</mat-icon>
														Ajouter une ligne
													</button>
												</ng-template>
											</mat-expansion-panel>

											<mat-expansion-panel [expanded]="true" class="custom-accordion-red">
												<mat-expansion-panel-header>
													<mat-panel-title style="flex-grow: 1">
														Consommations à déduire
														<app-help (click)="$event.stopPropagation()" [subject]="HelpSubject.DeductConso"></app-help>
													</mat-panel-title>
												</mat-expansion-panel-header>
												<table class="custom-table-consumption">
													<tr>
														<th class="corner"></th>
														<th>Énergie</th>
														<th *ngFor="let header of HEADERS_MONTHLY; index as i">
															{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
															{{
																(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
																	((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
															}}
														</th>
													</tr>
													<tbody
														app-consumption-table-row
														[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
														[start]="startTimestamp"
														[end]="endTimestamp"
														[colorTimeline]="'red'"
														[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.DEDUCT][category]"
														class="bigline"
														*ngFor="
															let category of functionalDeclaration.consumptions[EnergyCategory.DEDUCT] | objectKeys
														"
														(update)="fireUpdate(functionalDeclaration)"
														(updateInvoice)="updateInvoice(functionalDeclaration)"
														(editRow)="openAddRowModal($event, EnergyCategory.DEDUCT, functionalDeclaration, category)"
														[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
														[category]="EnergyCategory.DEDUCT"
														[unlockedYears]="functionalDeclaration.years_token_used"
														[isTokenConsumed]="functionalDeclaration.is_token_used"
													></tbody>
												</table>
												<button
													mat-flat-button
													class="btn-primary-light mt-2"
													(click)="openAddRowModal(undefined, EnergyCategory.DEDUCT, functionalDeclaration)"
												>
													<mat-icon>add</mat-icon>
													Ajouter une ligne
												</button>
											</mat-expansion-panel>
											<mat-expansion-panel
												[expanded]="false"
												class="custom-accordion-blue"
												*ngIf="(functionalDeclaration.consumptions[EnergyCategory.USAGE] | objectKeys).length > 0"
											>
												<mat-expansion-panel-header>
													<mat-panel-title style="flex-grow: 1"> Consommations par usage (facultatif) </mat-panel-title>
												</mat-expansion-panel-header>
												<table class="custom-table-consumption">
													<tr>
														<th class="corner"></th>
														<th>Énergie</th>
														<th *ngFor="let header of HEADERS_MONTHLY; index as i">
															{{ HEADERS_MONTHLY[(i + functionalDeclaration.infos.favoritePeriod) % 12] }}
															{{
																(functionalDeclaration.infos.favoritePeriod > 6 ? year - 1 : year) +
																	((i + functionalDeclaration.infos.favoritePeriod) / 12 | floor)
															}}
														</th>
													</tr>
													<tbody
														app-consumption-table-row
														[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
														[start]="startTimestamp"
														[end]="endTimestamp"
														[colorTimeline]="'blue'"
														[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.USAGE][category]"
														class="bigline"
														*ngFor="
															let category of functionalDeclaration.consumptions[EnergyCategory.USAGE] | objectKeys
														"
														(update)="fireUpdate(functionalDeclaration)"
														(updateInvoice)="updateInvoice(functionalDeclaration)"
														(editRow)="openAddRowModal($event, EnergyCategory.USAGE, functionalDeclaration, category)"
														[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
														[category]="EnergyCategory.USAGE"
														[unlockedYears]="functionalDeclaration.years_token_used"
														[isTokenConsumed]="functionalDeclaration.is_token_used"
													></tbody>
												</table>
											</mat-expansion-panel>
										</mat-accordion>
									</ng-container>
								</ng-container>
							</ng-container>
							<ng-container
								*ngIf="
									category === 0 &&
									selectedIndex === year - years[0] &&
									([functionalDeclaration] | lockedFunctionalDeclarationsForYear: year).length > 0
								"
							>
								<mat-spinner style="margin: 0 auto"></mat-spinner>
							</ng-container>
						</mat-tab>
					</mat-tab-group>
				</div>
			</div>
		</mat-tab>
		<mat-tab label="Vue d'ensemble">
			<ng-container *ngIf="category === 1">
				<div style="padding: 15px">
					Seules les années complètes seront éligibles à la recherche de l'année de référence. Une année est considérée
					comme complète lorsque l'ensemble des données sont renseignées sur les périodes de mise en service.<br />
					Renseigner l'ensemble des données est obligatoire pour 2021 et 2020 et est vivement recommandé pour les autres
					années.<br />
					Si vous n'avez aucune consommation sur une ligne de consommations (réparties ou communes par exemple), il est
					nécessaire d'indiquer "0" sur celle-ci. Dans le cas contraire, les consommations seront considérées comme
					"manquantes" et l'année ne pourra être sélectionnée comme année de référence.

					<div class="mt-3">
						<span
							class="danger link"
							matTooltip="Une fausse déclaration est passible des sanctions prévues au R. 441-6 du code pénal (deux ans d'emprisonnement et de 30 000 euros d'amende pour le déclarant). &quot;Des contrôles aléatoires pourront ainsi être menés chaque année pour vérifier l’exactitude des renseignements.&quot;"
						>
							Sanctions
						</span>
					</div>

					<div
						style="font-weight: 700; padding: 10px 0; width: 100%; text-align: center"
						tourAnchor="consumption-formula"
					>
						Consommation finale = <span class="brut-consumption">Consommations générales</span> -
						<span class="correction">Consommations à décompter</span>
					</div>
				</div>
				<ng-container *ngFor="let startTimestamp of [(MINIMUM_YEAR | periodRange: 0).start | dateToTimestamp]">
					<ng-container *ngFor="let endTimestamp of [(MAXIMUM_YEAR | periodRange: 0).end | dateToTimestamp]">
						<mat-accordion multi>
							<div *ngIf="stepError" class="danger step-error" id="stepErrorDiv">
								{{ Reason.NoConsumption | reasonToText }}
							</div>
							<mat-expansion-panel
								[expanded]="true"
								class="custom-accordion-green"
								tourAnchor="consumption-general"
								[class.b-1-danger]="stepError"
							>
								<mat-expansion-panel-header>
									<mat-panel-title style="flex-grow: 1"> Consommations générales </mat-panel-title>
								</mat-expansion-panel-header>
								<ng-container *ngIf="functionalDeclaration | isMultiOccupation; else elseBlock">
									<h2>
										Consommation individuelle
										<app-help [subject]="HelpSubject.IndividualConso"></app-help>
									</h2>
									<ng-container>
										<table class="custom-table-consumption" style="position: relative">
											<ng-container *ngIf="categoryNotEmpty(EnergyCategory.INDIVIDUAL)">
												<ng-container *ngTemplateOutlet="displayLocker"></ng-container>
											</ng-container>
											<tr>
												<th class="corner"></th>
												<th>Énergie</th>
												<th>{{ MINIMUM_YEAR }}</th>
												<th
													*ngFor="let header of years"
													[class]="{ 'required-year': header === 2020 || header === 2021 }"
													(click)="switchYear(header)"
													class="pointer"
												>
													{{ header }}
												</th>
											</tr>
											<tbody
												app-consumption-table-row
												[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
												[start]="startTimestamp"
												[end]="endTimestamp"
												[colorTimeline]="'green'"
												[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL][category]"
												class="bigline"
												*ngFor="
													let category of functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL] | objectKeys
												"
												[colSpan]="years.length + 1"
												[byYear]="true"
												(update)="fireUpdate(functionalDeclaration)"
												(updateInvoice)="updateInvoice(functionalDeclaration)"
												(goToDetail)="switchYear($event)"
												(editRow)="openAddRowModal($event, EnergyCategory.INDIVIDUAL, functionalDeclaration, category)"
												[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
												[category]="EnergyCategory.INDIVIDUAL"
												[tourAnchor]="
													category + '-' + EnergyCategory.INDIVIDUAL === categoryTourName
														? 'consumption-general-add-consumption-' + categoryTourName
														: ''
												"
												(saveWithTour)="startDeductTour()"
												[unlockedYears]="functionalDeclaration.years_token_used"
												[isTokenConsumed]="functionalDeclaration.is_token_used"
											></tbody>
										</table>
									</ng-container>
									<button
										mat-flat-button
										class="btn-primary-light mt-2"
										tourAnchor="consumption-general-add-row"
										(click)="openAddRowModal(undefined, EnergyCategory.INDIVIDUAL, functionalDeclaration)"
									>
										<mat-icon>add</mat-icon>
										Ajouter une ligne
									</button>
									<h2>
										Consommation commune
										<app-help [subject]="HelpSubject.SharedConso"></app-help>
									</h2>
									<table class="custom-table-consumption" style="position: relative">
										<ng-container *ngIf="categoryNotEmpty(EnergyCategory.COMMON)">
											<ng-container *ngTemplateOutlet="displayLocker"></ng-container>
										</ng-container>
										<tr>
											<th class="corner"></th>
											<th>Énergie</th>
											<th>{{ MINIMUM_YEAR }}</th>
											<th
												*ngFor="let header of years"
												[class]="{ 'required-year': header === 2020 || header === 2021 }"
												(click)="switchYear(header)"
												class="pointer"
											>
												{{ header }}
											</th>
										</tr>
										<tbody
											app-consumption-table-row
											[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
											[start]="startTimestamp"
											[end]="endTimestamp"
											[colorTimeline]="'green'"
											[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.COMMON][category]"
											class="bigline"
											*ngFor="let category of functionalDeclaration.consumptions[EnergyCategory.COMMON] | objectKeys"
											[colSpan]="years.length + 1"
											[byYear]="true"
											(update)="fireUpdate(functionalDeclaration)"
											(updateInvoice)="updateInvoice(functionalDeclaration)"
											(goToDetail)="switchYear($event)"
											(editRow)="openAddRowModal($event, EnergyCategory.COMMON, functionalDeclaration, category)"
											[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
											[category]="EnergyCategory.COMMON"
											[tourAnchor]="
												category + '-' + EnergyCategory.COMMON === categoryTourName
													? 'consumption-general-add-consumption-' + categoryTourName
													: ''
											"
											(saveWithTour)="startDeductTour()"
											[unlockedYears]="functionalDeclaration.years_token_used"
											[isTokenConsumed]="functionalDeclaration.is_token_used"
										></tbody>
									</table>
									<button
										mat-flat-button
										class="btn-primary-light mt-2"
										(click)="openAddRowModal(undefined, EnergyCategory.COMMON, functionalDeclaration)"
									>
										<mat-icon>add</mat-icon>
										Ajouter une ligne
									</button>
									<h2>
										Consommation répartie
										<app-help [subject]="HelpSubject.DistributedConso"></app-help>
									</h2>
									<table class="custom-table-consumption" style="position: relative">
										<ng-container *ngIf="categoryNotEmpty(EnergyCategory.DISTRIBUTED)">
											<ng-container *ngTemplateOutlet="displayLocker"></ng-container>
										</ng-container>
										<tr>
											<th class="corner"></th>
											<th>Énergie</th>
											<th>{{ MINIMUM_YEAR }}</th>
											<th
												*ngFor="let header of years"
												[class]="{ 'required-year': header === 2020 || header === 2021 }"
												(click)="switchYear(header)"
												class="pointer"
											>
												{{ header }}
											</th>
										</tr>
										<tbody
											app-consumption-table-row
											[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
											[start]="startTimestamp"
											[end]="endTimestamp"
											[colorTimeline]="'green'"
											[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.DISTRIBUTED][category]"
											class="bigline"
											*ngFor="
												let category of functionalDeclaration.consumptions[EnergyCategory.DISTRIBUTED] | objectKeys
											"
											[colSpan]="years.length + 1"
											[byYear]="true"
											(update)="fireUpdate(functionalDeclaration)"
											(updateInvoice)="updateInvoice(functionalDeclaration)"
											(goToDetail)="switchYear($event)"
											(editRow)="openAddRowModal($event, EnergyCategory.DISTRIBUTED, functionalDeclaration, category)"
											[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
											[category]="EnergyCategory.DISTRIBUTED"
											[tourAnchor]="
												category + '-' + EnergyCategory.DISTRIBUTED === categoryTourName
													? 'consumption-general-add-consumption-' + categoryTourName
													: ''
											"
											(saveWithTour)="startDeductTour()"
											[unlockedYears]="functionalDeclaration.years_token_used"
											[isTokenConsumed]="functionalDeclaration.is_token_used"
										></tbody>
									</table>
									<button
										mat-flat-button
										class="btn-primary-light mt-2"
										(click)="openAddRowModal(undefined, EnergyCategory.DISTRIBUTED, functionalDeclaration)"
									>
										<mat-icon>add</mat-icon>
										Ajouter une ligne
									</button>
								</ng-container>
								<ng-template #elseBlock>
									<table class="custom-table-consumption" style="position: relative">
										<ng-container *ngIf="categoryNotEmpty(EnergyCategory.INDIVIDUAL)">
											<ng-container *ngTemplateOutlet="displayLocker"></ng-container>
										</ng-container>
										<tr>
											<th class="corner"></th>
											<th>Énergie</th>
											<th>{{ MINIMUM_YEAR }}</th>
											<th
												*ngFor="let header of years"
												[class]="{ 'required-year': header === 2020 || header === 2021 }"
												(click)="switchYear(header)"
												class="pointer"
											>
												{{ header }}
											</th>
										</tr>
										<tbody
											app-consumption-table-row
											[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
											[start]="startTimestamp"
											[end]="endTimestamp"
											[colorTimeline]="'green'"
											[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL][category]"
											class="bigline"
											*ngFor="
												let category of functionalDeclaration.consumptions[EnergyCategory.INDIVIDUAL] | objectKeys
											"
											[colSpan]="years.length + 1"
											[byYear]="true"
											(update)="fireUpdate(functionalDeclaration)"
											(updateInvoice)="updateInvoice(functionalDeclaration)"
											(goToDetail)="switchYear($event)"
											(editRow)="openAddRowModal($event, EnergyCategory.INDIVIDUAL, functionalDeclaration, category)"
											[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
											[category]="EnergyCategory.INDIVIDUAL"
											[tourAnchor]="
												category + '-' + EnergyCategory.INDIVIDUAL === categoryTourName
													? 'consumption-general-add-consumption-' + categoryTourName
													: ''
											"
											(saveWithTour)="startDeductTour()"
											[unlockedYears]="functionalDeclaration.years_token_used"
											[isTokenConsumed]="functionalDeclaration.is_token_used"
										></tbody>
									</table>
									<button
										mat-flat-button
										class="btn-primary-light mt-2"
										tourAnchor="consumption-general-add-row"
										(click)="openAddRowModal(undefined, EnergyCategory.INDIVIDUAL, functionalDeclaration)"
									>
										<mat-icon>add</mat-icon>
										Ajouter une ligne
									</button>
								</ng-template>
							</mat-expansion-panel>
							<mat-expansion-panel [expanded]="true" class="custom-accordion-red" tourAnchor="consumption-deduct">
								<mat-expansion-panel-header>
									<mat-panel-title style="flex-grow: 1">
										Consommations à déduire
										<app-help (click)="$event.stopPropagation()" [subject]="HelpSubject.DeductConso"></app-help>
									</mat-panel-title>
								</mat-expansion-panel-header>
								<table class="custom-table-consumption" style="position: relative">
									<ng-container *ngIf="categoryNotEmpty(EnergyCategory.DEDUCT)">
										<ng-container *ngTemplateOutlet="displayLocker"></ng-container>
									</ng-container>
									<tr>
										<th class="corner"></th>
										<th>Énergie</th>
										<th>{{ MINIMUM_YEAR }}</th>
										<th
											*ngFor="let header of years"
											[class]="{ 'required-year': header === 2020 || header === 2021 }"
											(click)="switchYear(header)"
											class="pointer"
										>
											{{ header }}
										</th>
									</tr>
									<tbody
										app-consumption-table-row
										[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
										[start]="startTimestamp"
										[end]="endTimestamp"
										[colorTimeline]="'red'"
										[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.DEDUCT][category]"
										class="bigline"
										*ngFor="let category of functionalDeclaration.consumptions[EnergyCategory.DEDUCT] | objectKeys"
										[colSpan]="years.length + 1"
										[byYear]="true"
										(update)="fireUpdate(functionalDeclaration)"
										(updateInvoice)="updateInvoice(functionalDeclaration)"
										(goToDetail)="switchYear($event)"
										(editRow)="openAddRowModal($event, EnergyCategory.DEDUCT, functionalDeclaration, category)"
										[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
										[category]="EnergyCategory.DEDUCT"
										[unlockedYears]="functionalDeclaration.years_token_used"
										[isTokenConsumed]="functionalDeclaration.is_token_used"
									></tbody>
								</table>
								<button
									mat-flat-button
									class="btn-primary-light mt-2"
									(click)="openAddRowModal(undefined, EnergyCategory.DEDUCT, functionalDeclaration)"
								>
									<mat-icon>add</mat-icon>
									Ajouter une ligne
								</button>
							</mat-expansion-panel>
							<mat-expansion-panel
								[expanded]="false"
								class="custom-accordion-blue"
								*ngIf="(functionalDeclaration.consumptions[EnergyCategory.USAGE] | objectKeys).length > 0"
							>
								<mat-expansion-panel-header>
									<mat-panel-title style="flex-grow: 1"> Consommations par usage (facultatif) </mat-panel-title>
								</mat-expansion-panel-header>
								<table class="custom-table-consumption" style="position: relative">
									<ng-container *ngIf="categoryNotEmpty(EnergyCategory.USAGE)">
										<ng-container *ngTemplateOutlet="displayLocker"></ng-container>
									</ng-container>
									<tr>
										<th class="corner"></th>
										<th>Énergie</th>
										<th>{{ MINIMUM_YEAR }}</th>
										<th
											*ngFor="let header of years"
											[class]="{ 'required-year': header === 2020 || header === 2021 }"
											(click)="switchYear(header)"
											class="pointer"
										>
											{{ header }}
										</th>
									</tr>
									<tbody
										app-consumption-table-row
										[favoritePeriod]="functionalDeclaration.infos.favoritePeriod"
										[start]="startTimestamp"
										[end]="endTimestamp"
										[colorTimeline]="'blue'"
										[consumptionTable]="functionalDeclaration.consumptions[EnergyCategory.USAGE][category]"
										class="bigline"
										*ngFor="let category of functionalDeclaration.consumptions[EnergyCategory.USAGE] | objectKeys"
										[colSpan]="years.length + 1"
										[byYear]="true"
										(update)="fireUpdate(functionalDeclaration)"
										(updateInvoice)="updateInvoice(functionalDeclaration)"
										(goToDetail)="switchYear($event)"
										(editRow)="openAddRowModal($event, EnergyCategory.USAGE, functionalDeclaration, category)"
										[agreement]="category | agreementForSubCategory: functionalDeclaration.agreement_asks"
										[category]="EnergyCategory.USAGE"
										[unlockedYears]="functionalDeclaration.years_token_used"
										[isTokenConsumed]="functionalDeclaration.is_token_used"
									></tbody>
								</table>
							</mat-expansion-panel>
						</mat-accordion>
					</ng-container>
				</ng-container>
			</ng-container>
		</mat-tab>
	</mat-tab-group>

	<button
		*ngIf="(declarationState.get$ | async)?.value?.declaration_id as declarationId"
		mat-flat-button
		style="margin-top: 10px; margin-right: 5px"
		class="btn-primary"
		(click)="goToList(declarationId)"
	>
		Retour
	</button>

	<ng-template #displayLocker>
		<div>
			<div
				*ngIf="!functionalDeclaration?.is_token_used"
				class="locker_container"
				[ngStyle]="{
					left: 'calc( 3px + ( 100% / ' + (years.length + 3) + ' ) * 2 )',
					width:
						'calc( ( 100% / ' +
						(years.length + 3) +
						' ) * ' +
						(years.indexOf(MAXIMUM_YEAR_INITIAL_DECLARATION) + 2 + adaptToPeriod(MAXIMUM_YEAR_INITIAL_DECLARATION)) +
						' - 2px )',
					height: 'calc( 100% - 27px )'
				}"
				(click)="switchYear(MAXIMUM_YEAR_INITIAL_DECLARATION)"
			>
				<div class="locker">
					<mat-icon>lock</mat-icon>
				</div>
			</div>
		</div>
		<ng-container *ngFor="let year of years">
			<div
				*ngIf="
					year >= ANNUAL_DECLARATION_STARTING_YEAR &&
					([functionalDeclaration] | lockedFunctionalDeclarationsForYear: year).length > 0
				"
				class="locker_container"
				[ngStyle]="{
					left:
						'calc( 3px + ( 100% / ' +
						(years.length + 3) +
						' ) * ( ' +
						(years.indexOf(year) + 3 + adaptToPeriod(year)) +
						' ) )',
					width: 'calc( 100% / ' + (years.length + 3) + ' - 2px )',
					height: 'calc( 100% - 27px )'
				}"
				(click)="switchYear(year)"
			>
				<div class="locker">
					<mat-icon>lock</mat-icon>
				</div>
			</div>
		</ng-container>
	</ng-template>
</ng-container>

<ng-template #confirmPartialCsv let-data>
	<ng-container *ngIf="functionalDeclaration as functionalDeclaration">
		<div class="dialog-container">
			<div class="dialog-close-icon">
				<mat-icon class="close-icon" [mat-dialog-close]="undefined">close</mat-icon>
			</div>
			<div class="dialog-content pt-0">
				<h3 class="my-0">Attention, données inaccessibles</h3>
				<div class="dialog-small-text">
					<div class="d-flex-col gap-3">
						<div>
							Vous n’avez pas acheté l’intégralité des déclarations
							{{ data.initialDataWillBePartial ? "initiales" : "" }}
							{{ data.annualDataWillBePartial ? (data.initialDataWillBePartial ? " et " : "") + "annuelles" : "" }} pour
							cette EFA. Seules les données de consommation des années achetées apparaîtront dans le fichier.
						</div>
						<div>Voulez-vous tout de même télécharger vos données de consommation ?</div>
					</div>
				</div>
			</div>
			<div class="dialog-actions d-flex" style="justify-content: space-between">
				<button
					(click)="this.modalPartialCsvResult(false, functionalDeclaration)"
					class="btn-stroked-text-gray mr-auto"
				>
					Annuler
				</button>
				<button (click)="this.modalPartialCsvResult(true, functionalDeclaration)" class="btn-stroked-primary">
					Continuer
				</button>
			</div>
		</div>
	</ng-container>
</ng-template>
