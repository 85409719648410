import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, EMPTY, ReplaySubject, Subscription } from "rxjs";
import { catchError, first } from "rxjs/operators";
import { z } from "zod";
import { Rna, Siren } from "../../../../models/identifier";
import { dialogOpen } from "../../../../models/modal";
import { OperatApiErrorType } from "../../../../models/OperatApiErrorType";
import { OperatApiService } from "../../../../services/operat-api.service";
import { HelpSubject } from "../../../help/help.component";
import { ApiAccessErrorModalComponent } from "./api-access-error-modal/api-access-error-modal.component";

export type OperatApiImportData = {
	references: {
		refOperat: string;
		denomination: string;
		address: string;
	}[];
	userKey: string;
	structId: string;
};

@Component({
	selector: "app-operat-search-form",
	templateUrl: "./operat-search-form.component.html",
	styleUrls: ["./operat-search-form.component.scss"],
})
export class OperatSearchFormComponent implements OnDestroy {
	public errorIds: boolean = false;
	public unknownError = false;
	public errorUserRefs: boolean = false;
	public noDataToImport: boolean = false;
	public userReferences$ = new ReplaySubject<string[]>(1);
	// User references from adding modal input
	public userReferencesToAdd = "";
	public searchingEntities$ = new BehaviorSubject(false);
	private searchSubscription$?: Subscription;

	public importAll = true;
	public refErrors: string[] = [];

	@Input() public userKey = "";
	@Input() public inseeId = "";
	@Input() public editableInseeId = true;
	@Input() public editableUserKey = true;

	/**
	 * Save references to import along with userKey and structure ID, in case user changes input data before importing
	 */
	@Input() importData: OperatApiImportData = { references: [], userKey: "", structId: "" };
	@Output() public importDataChange = new EventEmitter<OperatApiImportData>();

	/**
	 * EFAs already linked to current declaration
	 */
	@Input() existingEfaRef: { refOperat: string; name: string }[] = [];

	@ViewChild("textArea") private textArea!: ElementRef;
	@ViewChild("duplicateEfa") private duplicateEfa!: TemplateRef<MatDialog>;
	@ViewChild("userReferencesModal") private userReferencesModal!: TemplateRef<MatDialog>;
	@ViewChild("addUserReferencesModal") private addUserReferencesModal!: TemplateRef<MatDialog>;

	constructor(
		private operatApi: OperatApiService,
		private dialog: MatDialog,
	) {
		this.userReferences$.next([]);
		this.userReferences$.subscribe(() => (this.refErrors = []));
	}

	ngOnDestroy() {
		if (this.searchSubscription$) {
			this.searchSubscription$.unsubscribe();
		}
	}

	parseReferences(event: ClipboardEvent) {
		event.preventDefault();
		const data = event.clipboardData?.getData("text/plain") ?? "";
		// Replace carriage return and ';' with ',' and remove extra spaces
		const replacedValue = this.filterReferencesWithoutSiret(
			data
				.replace(/[\n;]/g, ", ")
				.split(",")
				.map((ref) => ref.replace(/\s+/g, " ").trim())
				.filter((ref) => ref.length > 0),
		);

		// Insert in current selection and remove extra ',' from inserted data
		this.userReferencesToAdd =
			this.userReferencesToAdd.substring(0, this.textArea.nativeElement.selectionStart) +
			replacedValue.join(", ") +
			this.userReferencesToAdd.substring(this.textArea.nativeElement.selectionEnd);
	}

	searchEntities(userReferences: string[]) {
		this.errorIds = false;
		this.errorUserRefs = false;
		this.noDataToImport = false;
		this.refErrors = [];

		const userKeyCheck = z.string().uuid();
		if (
			!userKeyCheck.safeParse(this.userKey).success ||
			(!Rna.isValid(this.inseeId as Rna) && !Siren.isValid(this.inseeId as Siren))
		) {
			this.errorIds = true;
			return;
		}

		if (!this.importAll && userReferences.length === 0) {
			this.errorUserRefs = true;
			return;
		}

		this.resetImportData();
		this.searchingEntities$.next(true);
		this.unknownError = false;

		this.searchSubscription$ = this.operatApi
			.searchEntities(this.inseeId, this.userKey)
			.pipe(
				catchError((error: unknown) => {
					this.searchingEntities$.next(false);
					if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.BadRequest) {
						// In case of JSON error, throw
						try {
							switch (JSON.parse(error.error.error)[0]) {
								case OperatApiErrorType.INVALID_CLIENT:
									this.errorIds = true;
									break;
								case OperatApiErrorType.NOT_AUTHORIZED:
									dialogOpen(this.dialog, ApiAccessErrorModalComponent, undefined, { panelClass: "p-0" });
									break;
							}
							return EMPTY;
						} catch (_) {
							/* empty */
						}
					}
					this.unknownError = true;
					throw error;
				}),
			)
			.subscribe((apiReferences) => {
				this.searchingEntities$.next(false);
				let referenceToImport: { refOperat: string; address: string; denomination: string }[] = [];
				for (const refOperat of this.filterReferencesWithoutSiret(Object.keys(apiReferences))) {
					referenceToImport.push({ ...apiReferences[refOperat], refOperat: refOperat });
				}

				if (referenceToImport.length === 0) {
					this.noDataToImport = true;
					return;
				}

				if (this.existingEfaRef.length > 0) {
					// Remove already existing entities from found EFAs
					const filteredApiReferences = referenceToImport.filter(
						(efa) => !this.existingEfaRef.find((existingEfa) => existingEfa.refOperat === efa.refOperat),
					);
					if (filteredApiReferences.length < referenceToImport.length) {
						this.dialog.open(this.duplicateEfa, {
							data: {
								efas: this.existingEfaRef.filter((existingEfa) =>
									referenceToImport.find((efaData) => existingEfa.refOperat === efaData.refOperat),
								),
							},
						});
						referenceToImport = filteredApiReferences;
					}
				}

				if (this.importAll) {
					this.importData.userKey = this.userKey;
					this.importData.structId = this.inseeId;
					this.importData.references = referenceToImport;
				} else {
					for (const reference of userReferences) {
						if (!referenceToImport.find((refData) => refData.refOperat === reference)) {
							this.refErrors.push(reference);
						}
					}

					// If no error, import references given by user
					if (this.refErrors.length === 0) {
						this.importData.references = referenceToImport.filter((refData) =>
							userReferences.includes(refData.refOperat),
						);
						this.importData.userKey = this.userKey;
						this.importData.structId = this.inseeId;
					}
				}

				this.importDataChange.emit(this.importData);
			});
	}

	/**
	 * Remove references without SIRET (Identifier (first part of reference) without numbers)
	 * @param references
	 */
	filterReferencesWithoutSiret(references: string[]): string[] {
		const onlyLettersRegex = /^[a-zA-Z]+$/;
		return references.filter((ref) => !ref.split("_")[0].match(onlyLettersRegex));
	}

	resetImportData() {
		this.importData = { references: [], userKey: "", structId: "" };
		this.importDataChange.emit(this.importData);
	}

	openUserReferencesModal() {
		this.dialog.open(this.userReferencesModal, { panelClass: "p-0" });
	}

	openAddReferenceModal() {
		this.userReferencesToAdd = "";
		this.dialog.open(this.addUserReferencesModal, { panelClass: "p-0" });
	}

	addUserReferences() {
		this.userReferences$.pipe(first()).subscribe((references) => {
			const toAdd = this.userReferencesToAdd
				.split(",")
				.map((ref) => ref.trim())
				.filter((ref) => ref.length > 0 && !references.includes(ref));
			this.userReferences$.next([...references, ...toAdd]);
		});
	}

	removeReference(reference: string, userReferences: string[]) {
		this.userReferences$.next(userReferences.filter((ref) => ref !== reference));
	}

	protected readonly HelpSubject = HelpSubject;
}
