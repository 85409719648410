<div *ngFor="let firstLayer of categories">
	<ng-container *ngIf="firstLayer.itemsAndHidden">
		<h4 class="pointer mt-2 mb-1" (click)="firstLayer.itemsAndHidden.hidden = !firstLayer.itemsAndHidden.hidden">
			{{ firstLayer.title | categoryKeyToLabel }}
			<mat-icon
				class="expand-icon"
				[style]="{ transform: firstLayer.itemsAndHidden.hidden ? 'rotate(0deg)' : 'rotate(180deg)' }"
			>
				expand_more
			</mat-icon>
		</h4>
		<ul class="my-0" [hidden]="firstLayer.itemsAndHidden.hidden">
			<li *ngFor="let item of firstLayer.itemsAndHidden.items">
				<div
					class="d-flex mb-1"
					style="align-items: center"
					*ngFor="
						let value of [
							categorySurfaceAreas
								| displayFieldCategory: firstLayer.title : (firstLayer.title === item ? undefined : item)
						]
					"
				>
					<mat-checkbox
						color="primary"
						style="margin-right: 10px"
						[checked]="(value ?? 0) !== 0"
						(change)="toggleField(value ?? 0, firstLayer.title, firstLayer.title === item ? undefined : item)"
					>
						{{ item | categoryKeyToLabel }}
					</mat-checkbox>
					<ng-container *ngIf="value !== undefined">
						<input
							appInputRestriction="integer"
							#surfaceInput
							type="number"
							class="custom-input"
							[value]="value"
							(change)="setField(surfaceInput.value, firstLayer.title, firstLayer.title === item ? undefined : item)"
						/>
					</ng-container>
				</div>
			</li>
		</ul>
	</ng-container>
</div>
