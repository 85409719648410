<p class="my-0 pt-2">
	Les fichiers "CSV Consommations 20XX" contiennent les données de consommations annuelles de tous les établissements
	pour lesquels des données ont bien été renseignées sur cette même année. Pour les fichiers consommations année de
	référence, le nombre d'entités fonctionnelles incluses est précisé.
</p>
<ng-container>
	<div class="d-flex gap-2" style="flex-direction: column">
		<ng-container *ngIf="declaration$ | async as declaration">
			<ng-container *ngIf="declaration | apply: getFunctionalDeclarationsSelected as selectedFunctionalDeclarations">
				<div *ngFor="let conso of consos">
					<ng-container
						*ngIf="
							selectedFunctionalDeclarations
								| lockedFunctionalDeclarationsForYear: conso.year as lockedFunctionalDeclarations
						"
					>
						<h4 class="mb-0">
							Consommations {{ conso.year }}
							<a
								*ngIf="
									(declaration.declarations_functional | apply: getIncompleteFilterFunction(conso.year)).length > 0 &&
									lockedFunctionalDeclarations.length < selectedFunctionalDeclarations.length
								"
								class="danger pointer"
								(click)="scrollTo(conso.year)"
							>
								<mat-icon>error</mat-icon>
							</a>
							<app-help
								argument="{{ conso.year }}"
								[subject]="HelpSubject.EntitiesDeclaredAfterYear"
								*ngIf="(selectedFunctionalDeclarations | entitiesNotConcernedByYear: conso.year) > 0"
							></app-help>
						</h4>
						<div *ngFor="let csv of [conso.csv$ | async]">
							<ng-container
								[ngSwitch]="
									selectedFunctionalDeclarations
										| getConsoStateForCsv: lockedFunctionalDeclarations.length : csv : conso.year
								"
							>
								<ng-container *ngSwitchCase="'COMPLETE'">
									<!--csv is necessarily present if COMPLETE is returned by switch case-->
									<a
										*ngIf="csv"
										class="btn-stroked-primary badge-danger"
										[href]="csv.file"
										download="{{ csv.filename }}.csv"
									>
										<span class="text-icon">
											<mat-icon>download</mat-icon>
											{{
												csv.ids.length === 1
													? (csv.ids[0] | findFunctionalDeclarationInList: declaration.declarations_functional)?.name ??
														"Entité sans nom"
													: csv.ids.length + " entités fonctionnelles assujetties"
											}}
										</span>
									</a>
								</ng-container>
								<ng-container *ngSwitchCase="'PARTIAL'">
									<a
										*ngIf="csv"
										class="btn-stroked-warning badge-danger"
										(click)="openPartialCsvDialog(declaration, conso.year, csv)"
									>
										<span class="text-icon">
											<mat-icon>download</mat-icon>
											{{
												csv.ids.length === 1
													? (csv.ids[0] | findFunctionalDeclarationInList: declaration.declarations_functional)?.name ??
														"Entité sans nom"
													: csv.ids.length + " entités fonctionnelles assujetties"
											}}
										</span>
									</a>
								</ng-container>
								<ng-container *ngSwitchCase="'LOCKED'">
									<a
										class="btn-stroked-text-gray badge-danger"
										(click)="openLockedYearDialog(conso.year <= MAXIMUM_YEAR_INITIAL_DECLARATION)"
										style="position: relative"
									>
										<span class="text-icon">
											<mat-icon>download</mat-icon>
											{{ selectedFunctionalDeclarations[0].name }}
										</span>
										<mat-icon class="lock-icon" color="warn">lock</mat-icon>
									</a>
								</ng-container>
								<ng-container *ngSwitchCase="'EMPTY'">
									Le fichier généré est vide. Merci de vérifier la complétude des données de surfaces d'activités ou de
									consommations sur les entités et l'année concernées.
								</ng-container>
								<ng-container *ngSwitchCase="'NO DECLARATION'">
									Le fichier généré est vide. Aucune entité n'a accès aux déclarations de consommations de cette année
									(assujettissement ultérieur à
									{{ conso.year }})
								</ng-container>
							</ng-container>
						</div>
					</ng-container>
				</div>
				<div
					*ngIf="
						selectedFunctionalDeclarations.length >
						(selectedFunctionalDeclarations | entitiesNotConcernedByYear: ANNUAL_DECLARATION_STARTING_YEAR - 1)
					"
				>
					<ng-container *ngFor="let referenceConso of [referenceConso$ | async]">
						<h4 class="mb-0">
							Consommations de référence
							<a
								*ngIf="!referenceConso || (declaration.declarations_functional | apply: missingReferenceData)"
								class="danger pointer"
								(click)="scrollTo(2021)"
							>
								<mat-icon>error</mat-icon>
							</a>
						</h4>
						<div>
							<ng-container
								[ngSwitch]="
									selectedFunctionalDeclarations
										| getConsoStateForCsv
											: (selectedFunctionalDeclarations | apply: numberOfLockedFunctionalDeclarationsForReferenceYear)
											: referenceConso
								"
							>
								<ng-container *ngSwitchCase="'COMPLETE'">
									<!--referenceConso is necessarily present if COMPLETE is returned by switch case-->
									<a
										*ngIf="referenceConso"
										class="btn-stroked-primary badge-danger"
										[href]="referenceConso.file"
										download="{{ referenceConso.filename }}.csv"
									>
										<span class="text-icon">
											<mat-icon>download</mat-icon>
											{{
												referenceConso.ids.length === 1
													? (
															referenceConso.ids[0]
															| findFunctionalDeclarationInList: declaration.declarations_functional
														)?.name ?? "Entité sans nom"
													: referenceConso.ids.length + " entités fonctionnelles assujetties"
											}}
										</span>
									</a>
								</ng-container>
								<ng-container *ngSwitchCase="'PARTIAL'">
									<a
										*ngIf="referenceConso"
										class="btn-stroked-warning badge-danger"
										(click)="openPartialCsvDialog(declaration, null, referenceConso)"
									>
										<span class="text-icon">
											<mat-icon>download</mat-icon>
											{{
												referenceConso.ids.length === 1
													? (
															referenceConso.ids[0]
															| findFunctionalDeclarationInList: declaration.declarations_functional
														)?.name ?? "Entité sans nom"
													: referenceConso.ids.length + " entités fonctionnelles assujetties"
											}}
										</span>
									</a>
								</ng-container>
								<ng-container *ngSwitchCase="'LOCKED'">
									<a
										class="btn-stroked-text-gray badge-danger"
										(click)="openLockedYearDialog()"
										style="position: relative"
									>
										<span class="text-icon">
											<mat-icon>download</mat-icon>
											{{ selectedFunctionalDeclarations[0].name }}
										</span>
										<mat-icon class="lock-icon" color="warn">lock</mat-icon>
									</a>
								</ng-container>
								<ng-container *ngSwitchCase="'EMPTY'">
									Vos surfaces d'activités ou vos consommations de référence sont nulles.
								</ng-container>
								<ng-container *ngSwitchCase="'NO DECLARATION'"> </ng-container>
							</ng-container>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</ng-container>
	</div>
</ng-container>
