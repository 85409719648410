<div *ngIf="!hidden">
	<mat-slide-toggle
		color="primary"
		[disabled]="disabled"
		[(ngModel)]="isNewlyLiable"
		(ngModelChange)="isNewlyLiableChange.emit($event)"
	>
		<div class="d-flex">
			<div class="text">Je suis nouvellement assujetti</div>
			<div><app-help [subject]="HelpSubject.NewlyLiable"></app-help></div>
		</div>
	</mat-slide-toggle>
	<div class="custom-input-group" *ngIf="isNewlyLiable">
		<label required>Année d'entrée d'assujettissement</label>
		<input
			appInputRestriction="integer"
			placeholder="Année d'entrée d'assujettissement"
			class="custom-input"
			type="number"
			[ngClass]="{
				disabled: disabled
			}"
			[readonly]="disabled"
			[min]="ANNUAL_DECLARATION_STARTING_YEAR"
			[max]="TODAY"
			[(ngModel)]="firstYearDeclaration"
			(ngModelChange)="firstYearDeclarationChange.emit($event)"
			required
		/>
		<span class="custom-input-error d-block">{{ firstYearDeclaration | zodSafeParse: zodCheckFirstYear }}</span>
	</div>
</div>
