<span class="d-inline-flex gap-1 w-100">
	<div class="custom-input-group w-100">
		<input
			#input
			class="custom-input w-100"
			[placeholder]="placeholder"
			[maxLength]="14"
			[minLength]="14"
			[(ngModel)]="siret"
			(ngModelChange)="
				siretChange.emit($event); siret.length === 14 ? checkSiret(input, siret) : resetCustomValidity(input)
			"
			required
		/>
		<span class="custom-input-error">{{ input.validationMessage }}</span>
	</div>
	<button
		[disabled]="!(siret | apply: Siret.isValid) || tasksCount > 0"
		[ngClass]="{
			'btn-squared': true,
			'btn-primary': !validSirets.has(siret),
			'btn-accent': validSirets.get(siret) === true,
			'btn-danger': validSirets.get(siret) === false
		}"
		class="btn-primary btn-squared"
		style="width: 40px; height: 40px"
		(click)="validSirets.has(siret) ? undefined : checkSiret(input, siret)"
		[matTooltip]="
			!validSirets.has(siret)
				? (siret | apply: Siret.isValid)
					? 'Vérifier le SIRET en ligne'
					: 'Votre SIRET n\'a pas un bon format (14 chiffres)'
				: validSirets.get(siret)
					? 'Votre SIRET existe'
					: 'Votre SIRET n\'existe pas'
		"
	>
		<mat-icon>
			{{ !validSirets.has(siret) ? "search" : validSirets.get(siret) ? "check" : "close" }}
		</mat-icon>
	</button>
</span>
<ng-container *ngIf="validSirets.get(siret) === false">
	Mon SIRET n'est pas détecté correctement <app-help [subject]="HelpSubject.MaybeSirenDoesntExist"></app-help>
</ng-container>
