<div class="w-100">
	<ng-container *ngIf="graphData as graphData">
		<div class="text-gray">
			En raison de la crise sanitaire, les années 2020 et 2021 ne peuvent pas constituer une année de référence (même si
			les consommations de ces années sont plus importantes). L'année de référence doit être comprise entre 2010 et
			2019, sinon elle correspondra à votre première année pleine d'exploitation (2022)
		</div>
		<div
			*ngIf="
				absoluteObjectives[CURRENT_COMPLETE_YEAR] === undefined ||
				absoluteObjectives[CURRENT_COMPLETE_YEAR] === WRONG_ABSOLUTE_OBJECTIVE
			"
			class="alert-danger mt-1"
			style="text-align: justify"
		>
			<ng-container *ngIf="functionalDeclaration.address | isInDomTom; else errorSubCategory">
				Les catégories d'activité ne sont pas décrites dans les textes officiels pour les Départements d'Outre-Mer. Par
				conséquent, il est impossible de calculer l'objectif en valeur absolue ainsi que les corrections à appliquer aux
				consommations annuelles. <br />
				Le choix de l'année de référence pourrait donc être impacté.
			</ng-container>
			<ng-template #errorSubCategory>
				Les sous-catégories d'activité renseignées n'ont pas toutes été décrites dans les textes officiels. Il est donc
				<b>impossible de calculer l'objectif en valeur absolue</b>. <br />Les formules de corrections sont également
				impactées et le choix de
				<b>l'année de référence peut alors être erroné</b>
				(notamment si des années sont similaires avant les corrections).
			</ng-template>
		</div>
		<div *ngIf="functionalDeclaration.is_imported_from_operat" class="alert-danger mt-1" style="text-align: justify">
			Impossible de récupérer l'année de référence et l'objectif relatif/absolu depuis OPERAT pour le moment.
		</div>
		<div class="d-flex" style="justify-content: space-between">
			<div
				class="w-100"
				*ngFor="
					let referenceYear of [
						functionalDeclaration | getReferenceYearForFunctionalDeclaration: djus : absoluteObjectives
					]
				"
			>
				<h3>{{ referenceYear ? "Année sélectionnée: " + referenceYear : "Pas d'année sélectionnée." }}</h3>
				<ng-container *ngIf="functionalDeclaration | allConsumptionsIncomplete">
					<ng-container *ngIf="this.referenceYear; else noReference">
						<div class="alert-danger mt-1" style="text-align: justify">
							Attention, {{ referenceYear }} a été choisie comme année de référence en raison d'une incomplétude des
							données sur les années antérieures à 2019. Nous vous recommandons de vérifier la complétude des données
							entre 2010 et 2019 pour vous assurer que ce choix est légitime.<br />
							Pour qu'OPERAT identifie {{ referenceYear }} comme année de référence, il suffit de ne pas importer de
							fichier "Consommations de référence" pour l'entité en question.
						</div>
					</ng-container>
					<ng-template #noReference>
						<h3 class="danger">
							Toutes vos années sont incomplètes, vous ne pouvez pas choisir une année de référence.
						</h3>
					</ng-template>
				</ng-container>

				<h3 *ngIf="functionalDeclaration | getReport: djus : absoluteObjectives : referenceYear as report">
					<span class="primary">Objectif relatif:</span>
					{{
						report.correctedConsumption * (objectiveToShow | apply: getObjectiveRateFromReferenceYear) | numberUnit
					}}Wh/m²
				</h3>
				<h3>
					<span class="absolute-objective">Objectif Absolu: </span>
					<ng-container
						*ngFor="let currentAbsoluteObjective of [absoluteObjectives | apply: getCurrentAbsoluteObjective]"
					>
						<ng-container
							*ngIf="currentAbsoluteObjective === undefined || currentAbsoluteObjective === WRONG_ABSOLUTE_OBJECTIVE"
						>
							<span
								matTooltip="Il est actuellement impossible de calculer l'objectif absolu car les sous-catégories d'activité choisies n'ont pas encore été décrites dans les textes officiels. Cet objectif sera calculé dès que les informations nécessaires paraîtront."
								>Impossible de calculer l'objectif absolu</span
							>
						</ng-container>
						<ng-container *ngIf="currentAbsoluteObjective && currentAbsoluteObjective > 0">
							{{ currentAbsoluteObjective * (objectiveToShow | apply: getObjectiveRate) | numberUnit }}Wh/m²
						</ng-container>
					</ng-container>
				</h3>
				<!--        <h3>-->
				<!--          <span class="text-secondary">Évolution par rapport à l'année de référence: </span>-->
				<!--          <ng-container *ngIf="getEnergySaving() as saving">-->
				<!--            <span [ngClass]="{'success': saving.percent <= 0, 'danger': saving.percent > 0}" >-->
				<!--              {{saving.percent > 0 ? "+" : ""}}{{saving.percent}}-->
				<!--            </span>% ( {{saving.raw < 0 ? "-" : ""}}{{saving.raw|numberUnit}}Wh/m² ) ({{saving.years}})-->
				<!--          </ng-container>-->
				<!--          <ng-container *ngIf="!getEnergySaving()">Impossible de calculer l'économie réalisée</ng-container>-->
				<!--        </h3>-->
			</div>
			<div class="w-100">
				<table *ngIf="reportForFraction as report" style="margin-left: auto">
					<tr>
						<th colspan="5">{{ report.year }}</th>
					</tr>
					<tr>
						<th class="brut-consumption">Consommation générale</th>
						<th style="font-size: 1.3rem" rowspan="2">{{ report.correction < 0 ? "-" : "+" }}</th>
						<th class="correction">Correction</th>
						<th style="font-size: 1.3rem" rowspan="4">=</th>
						<th class="corrected-consumption">Consommation corrigée</th>
					</tr>
					<tr>
						<td style="text-align: center">{{ report.total | numberUnit }}Wh</td>
						<td style="text-align: center">{{ report.correction | numberUnit }}Wh</td>
						<td style="text-align: center" rowspan="2">
							{{
								(report.total + report.correction) / (functionalDeclaration | sumCategorySurfacePerYear)[report.year]
									| numberUnit
							}}Wh/m²
						</td>
					</tr>
					<tr>
						<th colspan="3" class="accent2" style="border-top: 1px solid black">Surface</th>
					</tr>
					<tr>
						<td colspan="3" style="text-align: center">
							{{ (functionalDeclaration | sumCategorySurfacePerYear)[report.year] | number: "1.0-0" }}
							m²
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div *ngIf="hasInfinityValue" class="message-danger mb-2 d-flex-col">
			<p class="m-0">
				Vous avez renseigné des données de consommations sur une période pour laquelle aucune surface d’activité n’a été
				renseignée. Les ratios de consommations (kWh/m²) sont donc inexploitables pour les années concernées. Malgré
				cela, le Configurateur sélectionnera une année de référence parmi les années valides, et générera des fichiers
				CSV associées corrects.
			</p>

			Pour outrepasser ce problème, vous pouvez :
			<ul class="m-0">
				<li>
					Supprimer les données de consommations problématiques, ou compléter les surfaces d’activités manquantes,
				</li>
				<li>
					Ignorer le message : il est possible de zoomer sur les années valides (à l’opposé de l’année problématique),
					pour contourner le souci de graphique « écrasé » et pour visualiser l’année de référence notamment.
				</li>
			</ul>
		</div>
		<table style="table-layout: fixed; width: 100%">
			<tr>
				<td></td>
				<td>
					<div
						class="d-flex"
						style="justify-content: center"
						*ngFor="let lastObjectiveIndex of [OBJECTIVE_YEAR.length - 1]"
					>
						<button
							*ngFor="let objective of OBJECTIVE_YEAR; index as i"
							[class.btn-stroked-primary]="objective === objectiveToShow"
							[class.btn-stroked-text-gray]="objective !== objectiveToShow"
							(click)="setObjectiveToShow(graphData, objective)"
							mat-stroked-button
							[style]="{
								'border-radius':
									(i === 0 ? '4px ' : '0 ') +
									(i === lastObjectiveIndex ? '4px ' : '0 ') +
									(i === lastObjectiveIndex ? '4px ' : '0 ') +
									(i === 0 ? '4px ' : '0 ')
							}"
						>
							Objectif {{ objective }}
						</button>
					</div>
				</td>
				<td>
					<div class="d-flex" style="justify-content: flex-end">
						<button
							mat-stroked-button
							[disabled]="!exportFile"
							class="btn-stroked-primary"
							style="border-radius: 4px"
							(click)="downloadExport()"
						>
							<mat-icon>download</mat-icon>
							Exporter les données
						</button>
					</div>
				</td>
			</tr>
		</table>
	</ng-container>
	<div #chartDiv id="chartdiv-{{ instance }}" class="chartdiv" [style]="{ height: chartHeight + 'px' }"></div>
	<div class="text-gray p-2">
		Les corrections ne sont pas encore disponibles pour l'année 2022. Elles le seront une fois que l'API OPERAT sera
		déployée. Par conséquent, la valeur en kWh/m² renseignée pour 2022 n'est pas représentative de la réalité.
	</div>
</div>
