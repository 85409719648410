import { registerLocaleData } from "@angular/common";

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouterModule } from "@angular/router";
import { NgxMapLibreGLModule } from "@maplibre/ngx-maplibre-gl";
import { NgSelectModule } from "@ng-select/ng-select";
import { CountUpModule } from "ngx-countup";

import { MarkdownModule } from "ngx-markdown";
import { NgScrollbarModule } from "ngx-scrollbar";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AddFunctionalEntityModalComponent } from "./components/add-functional-entity-modal/add-functional-entity-modal.component";
import { AgreementsComponent } from "./components/agreements/agreements.component";
import { AuthorizationViewComponent } from "./components/authorization-view/authorization-view.component";
import { ResourceLinkComponent } from "./components/authorization-view/resource-link/resource-link.component";
import { AvatarComponent } from "./components/avatar/avatar.component";
import { ChangelogComponent } from "./components/changelog/changelog.component";
import { ColorPickerComponent } from "./components/color-picker/color-picker.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import { CustomFormComponent } from "./components/custom-form/custom-form.component";
import { DeclarationsCreationComponent } from "./components/declarations-creation/declarations-creation.component";
import { FileButtonComponent } from "./components/file-button/file-button.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { DialogComponent as HelpDialogComponent, HelpComponent } from "./components/help/help.component";
import { IdentifierModalComponent } from "./components/identifier-modal/identifier-modal.component";
import { IdentifierViewComponent } from "./components/identifier-view/identifier-view.component";
import { ImageInputComponent } from "./components/image-input/image-input.component";
import { InputConfirmComponent } from "./components/input-confirm/input-confirm.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { LoginComponent } from "./components/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { MyMapComponent } from "./components/my-map/my-map.component";
import { AdvancementChipComponent } from "./components/navigation/advancement-chip/advancement-chip.component";
import { AuthorizationListModalComponent } from "./components/navigation/authorization-list-modal/authorization-list-modal.component";
import { BuyTokenFormComponent } from "./components/navigation/buy-token/buy-token-form/buy-token-form.component";
import { BuyTokenComponent } from "./components/navigation/buy-token/buy-token.component";
import { ExportCsvModalComponent } from "./components/navigation/export-csv-modal/export-csv-modal.component";
import { FirstConnectionModalComponent } from "./components/navigation/first-connection-modal/first-connection-modal.component";
import { DeleteGroupConfirmModalComponent } from "./components/navigation/my-declarations/delete-group-confirm-modal/delete-group-confirm-modal.component";
import { DeclarationRowComponent } from "./components/navigation/my-declarations/group-pane/declaration-row/declaration-row.component";
import { GroupPaneComponent } from "./components/navigation/my-declarations/group-pane/group-pane.component";
import { MyDeclarationsComponent } from "./components/navigation/my-declarations/my-declarations.component";
import { NewDeclarationModalComponent } from "./components/navigation/my-declarations/new-declaration-modal/new-declaration-modal.component";
import { ProgressModalComponent } from "./components/navigation/my-declarations/progress-modal/progress-modal.component";
import { StatChartMapComponent } from "./components/navigation/my-declarations/stats/stat-chart/stat-chart-map/stat-chart-map.component";
import { StatChartPerCategoryComponent } from "./components/navigation/my-declarations/stats/stat-chart/stat-chart-per-category/stat-chart-per-category.component";
import { StatChartPerEnergyComponent } from "./components/navigation/my-declarations/stats/stat-chart/stat-chart-per-energy/stat-chart-per-energy.component";
import { StatsComponent } from "./components/navigation/my-declarations/stats/stats.component";
import { AddressFormComponent } from "./components/navigation/my-profile/address-form/address-form.component";
import { MyProfileComponent } from "./components/navigation/my-profile/my-profile.component";
import { NavigationComponent } from "./components/navigation/navigation.component";
import { NewVersionModalComponent } from "./components/navigation/new-version-modal/new-version-modal.component";
import { PdfReportComponent } from "./components/navigation/pdf-report/pdf-report.component";
import { RepresentativeSettingsComponent } from "./components/navigation/representative-settings/representative-settings.component";
import { BenchmarkGraphComponent } from "./components/navigation/stepper/benchmark-modal/benchmark-graph/benchmark-graph.component";
import { BenchmarkModalComponent } from "./components/navigation/stepper/benchmark-modal/benchmark-modal.component";
import { CsvComponent } from "./components/navigation/stepper/csv/csv.component";
import { DeclarationFunctionalListDialogComponent } from "./components/navigation/stepper/declaration-functional-list-dialog/declaration-functional-list-dialog.component";
import { DeclarationFunctionalListComponent } from "./components/navigation/stepper/declaration-functional-list-dialog/declaration-functional-list/declaration-functional-list.component";
import { AgreementContainerComponent } from "./components/navigation/stepper/functional-entity-stepper/agreement-container/agreement-container.component";
import { AgreementComponent } from "./components/navigation/stepper/functional-entity-stepper/agreement-container/agreement/agreement.component";
import { ActivityCategoryModalComponent } from "./components/navigation/stepper/functional-entity-stepper/asset-declaration/activity-category-modal/activity-category-modal.component";
import { ActivityCategoryTableComponent } from "./components/navigation/stepper/functional-entity-stepper/asset-declaration/activity-category-table/activity-category-table.component";
import { AgreementModalComponent } from "./components/navigation/stepper/functional-entity-stepper/asset-declaration/agreement-modal/agreement-modal.component";
import { AssetDeclarationComponent } from "./components/navigation/stepper/functional-entity-stepper/asset-declaration/asset-declaration.component";
import { AssetMapComponent } from "./components/navigation/stepper/functional-entity-stepper/asset-declaration/asset-map/asset-map.component";
import { ConsumeTokenDialogComponent } from "./components/navigation/stepper/functional-entity-stepper/asset-declaration/consume-token-dialog";
import { ElectricCarDockModalComponent } from "./components/navigation/stepper/functional-entity-stepper/asset-declaration/electric-car-dock-modal/electric-car-dock-modal.component";
import { HelperModalComponent } from "./components/navigation/stepper/functional-entity-stepper/asset-declaration/helper-modal/helper-modal.component";
import { BuildingModalComponent } from "./components/navigation/stepper/functional-entity-stepper/building-modal/building-modal.component";
import { LotNumberModalComponent } from "./components/navigation/stepper/functional-entity-stepper/building-modal/lot-number-modal/lot-number-modal.component";
import { OptionalBuildingDataSelectComponent } from "./components/navigation/stepper/functional-entity-stepper/building-modal/optional-data-building-modal/optional-building-data-select/optional-building-data-select.component";
import { OptionalDataBuildingComponent } from "./components/navigation/stepper/functional-entity-stepper/building-modal/optional-data-building-modal/optional-data-building.component";
import { AddDeliveryValuesModalComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/add-delivery-values-modal/add-delivery-values-modal.component";
import { CsvDeliveryHelpModalComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/add-delivery-values-modal/csv-delivery-help-modal/csv-delivery-help-modal.component";
import { ImportDeliveryCsvModalComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/add-delivery-values-modal/import-delivery-csv-modal/import-delivery-csv-modal.component";
import { AddValueModalComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/add-value-modal/add-value-modal.component";
import { CsvHelpModalComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/add-value-modal/csv-help-modal/csv-help-modal.component";
import { ImportCsvModalComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/add-value-modal/import-csv-modal/import-csv-modal.component";
import { ConsoListComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/conso-list/conso-list.component";
import { AddRowModalComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/consumption-table/add-row-modal/add-row-modal.component";
import { EditUmpteenthModalComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/consumption-table/add-row-modal/edit-umpteenth-modal/edit-umpteenth-modal.component";
import { ConsumptionTableExportComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/consumption-table/consumption-table-export/consumption-table-export.component";
import { ConsumptionTableRowComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/consumption-table/consumption-table-row/consumption-table-row.component";
import { ConsumptionTableComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/consumption-table/consumption-table.component";
import { ProofFilesModalsComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/proof-files-modals/proof-files-modals.component";
import { FunctionalEntityStepperComponent } from "./components/navigation/stepper/functional-entity-stepper/functional-entity-stepper.component";
import { ParcelModalComponent } from "./components/navigation/stepper/functional-entity-stepper/parcel-modal/parcel-modal.component";
import { BuildingMapComponent } from "./components/navigation/stepper/functional-entity-stepper/qualification-form-map/building-map/building-map.component";
import { QualificationFormMapComponent } from "./components/navigation/stepper/functional-entity-stepper/qualification-form-map/qualification-form-map.component";
import { AreaHelpModalComponent } from "./components/navigation/stepper/functional-entity-stepper/qualification-form-map/qualification-form/area-help-modal/area-help-modal.component";
import { CategoryListComponent } from "./components/navigation/stepper/functional-entity-stepper/qualification-form-map/qualification-form/category-list/category-list.component";
import { AsNumberPipe } from "./components/navigation/stepper/functional-entity-stepper/qualification-form-map/qualification-form/category-list/pipes/as-number.pipe";
import { AsRecordPipe } from "./components/navigation/stepper/functional-entity-stepper/qualification-form-map/qualification-form/category-list/pipes/as-record.pipe";
import { GetNumberFromMaskPipe } from "./components/navigation/stepper/functional-entity-stepper/qualification-form-map/qualification-form/category-list/pipes/get-number-from-mask.pipe";
import { HasOneFieldGreaterThanZeroPipe } from "./components/navigation/stepper/functional-entity-stepper/qualification-form-map/qualification-form/category-list/pipes/has-one-field-greater-than-zero.pipe";
import { SurfaceTabsComponent } from "./components/navigation/stepper/functional-entity-stepper/qualification-form-map/qualification-form/surface-tabs/surface-tabs.component";
import { SurfaceModalComponent } from "./components/navigation/stepper/functional-entity-stepper/surface-modal/surface-modal.component";
import { YearReferenceDataComponent } from "./components/navigation/stepper/functional-entity-stepper/year-reference/year-reference-data/year-reference-data.component";
import { YearReferenceComponent } from "./components/navigation/stepper/functional-entity-stepper/year-reference/year-reference.component";
import { ReferenceYearSummaryTableComponent } from "./components/navigation/stepper/reference-year-summary/reference-year-summary-table/reference-year-summary-table.component";
import { ReferenceYearSummaryComponent } from "./components/navigation/stepper/reference-year-summary/reference-year-summary.component";
import { EditAddressModalComponent } from "./components/navigation/stepper/siren-form/edit-address-modal/edit-address-modal.component";
import { SirenFormComponent } from "./components/navigation/stepper/siren-form/siren-form.component";

import { StepperComponent } from "./components/navigation/stepper/stepper.component";
import { TokenButtonComponent } from "./components/navigation/token-button/token-button.component";
import { TokenTransferModalComponent } from "./components/navigation/token-transfer-modal/token-transfer-modal.component";
import { UserInfoFormComponent } from "./components/navigation/user-info-form/user-info-form.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { PaymentComponent } from "./components/payment/payment.component";
import { PaymentSliderComponent } from "./components/payment/slider/payment-slider.component";
import { StandaloneProgressModalComponent } from "./components/progress-modal/progress-modal.component";
import { ProgressPercentageComponent } from "./components/progress-percentage/progress-percentage.component";
import { RegisterFormComponent } from "./components/register/register-form/register-form.component";
import { RegisterComponent } from "./components/register/register.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { SirenInputComponent } from "./components/siren-input/siren-input.component";
import { SiretInputComponent } from "./components/siret-input/siret-input.component";
import { WelcomeComponent } from "./components/welcome/welcome.component";
import { InputRestrictionDirective } from "./directives/input-restriction.directive";
import { AuthInterceptor } from "./interceptor/auth/auth.interceptor";
import { GlobalErrorHandler } from "./interceptor/global-error-handler";
import { VersionInterceptor } from "./interceptor/version.interceptor";
import { AbsPipe } from "./pipes/abs.pipe";
import { AddressListPipe } from "./pipes/address-list.pipe";
import { AddressToStringPipe } from "./pipes/address-to-string.pipe";
import { AgreementCanEditPipe } from "./pipes/agreement-can-edit";
import { AgreementForSubCategoryPipe } from "./pipes/agreement-for-sub-category.pipe";
import { AllConsumptionsIncompletePipe } from "./pipes/all-consumptions-incomplete.pipe";
import { ApplyPipe } from "./pipes/apply.pipe";
import { AreAnyRepartitionKeysDatesWrongPipe } from "./pipes/are-any-repartition-keys-dates-wrong.pipe";
import { AreDatesWrongPipe } from "./pipes/are-dates-wrong.pipe";
import { AsAutrePipe } from "./pipes/as-autre.pipe";
import { AsHolderDisplayTypePipe } from "./pipes/as-holder-display-type.pipe";
import { AsOtherStructurePipe } from "./pipes/as-other-structure.pipe";
import { AsOtherPipe } from "./pipes/as-other.pipe";
import { AsRefEtatLessPipe } from "./pipes/as-ref-etat-less.pipe";
import { AsRefEtatPipe } from "./pipes/as-ref-etat.pipe";
import { AsRnaStructurePipe } from "./pipes/as-rna-structure.pipe";
import { AsRnaPipe } from "./pipes/as-rna.pipe";
import { AsSirenStructurePipe } from "./pipes/as-siren-structure.pipe";
import { AsSirenPipe } from "./pipes/as-siren.pipe";
import { AsSiretPipe } from "./pipes/as-siret.pipe";
import { AsStreetTypePipe } from "./pipes/as-street-type.pipe";
import { AssujettisementCasePipe } from "./pipes/assujettisement-case.pipe";
import { BuildingAssujettiPipe } from "./pipes/building-assujetti.pipe";
import { CanGetAutomaticallyPipe } from "./pipes/can-get-automatically.pipe";
import { CanNotReachStepPipe } from "./pipes/can-not-reach-step.pipe";
import { CanReachStepPipe } from "./pipes/can-reach-step.pipe";
import { CantGetAutomaticallyReasonPipe } from "./pipes/cant-get-automatically-reason.pipe";
import { CategoryFromCodePipe } from "./pipes/category-from-code.pipe";
import { CategoryKeyToLabelPipe } from "./pipes/category-key-to-label.pipe";
import { CategoryStartPipe } from "./pipes/category-start.pipe";
import { CeilPipe } from "./pipes/ceil.pipe";
import { CheckDeclarationStatePipe } from "./pipes/check-declaration-state.pipe";
import { CheckErrorsEntityFormatPipe } from "./pipes/check-errors-entity-format.pipe";
import { CheckErrorsFormatPipe } from "./pipes/check-errors-format.pipe";
import { CheckFunctionalDeclarationStatePipe } from "./pipes/check-functional-declaration-state.pipe";
import { CloneFeatureCollectionPipe } from "./pipes/clone-feature-collection.pipe";
import { CollapsableStatePipe } from "./pipes/collapsable-state.pipe";
import { ConsumptionEntryByYearPipe } from "./pipes/consumption-entry-by-year.pipe";
import { ContainsPipe } from "./pipes/contains.pipe";
import { CorrectedValuePipe } from "./pipes/corrected-value.pipe";
import { CountDeclarationsPipe } from "./pipes/count-declarations.pipe";
import { CountEntitiesPipe } from "./pipes/count-entities.pipe";
import { DateToTimestampPipe } from "./pipes/date-to-timestamp.pipe";
import { DisplayFieldCategoryPipe } from "./pipes/display-field-category.pipe";
import { DisplayableConsumptionEntryPipe } from "./pipes/displayable-consumption-entry.pipe";
import { EnergyCategoriesLabelPipe } from "./pipes/energy-categories-label.pipe";
import { EnergyTypeFromStringPipe } from "./pipes/energy-type-from-string.pipe";
import { EnergyTypeTitlePipe } from "./pipes/energy-type-title.pipe";
import { EnergyTypeUnitPipe } from "./pipes/energy-type-unit.pipe";
import { EnergyTypeValueDisplayPipe } from "./pipes/energy-type-value-display.pipe";
import { EntitiesToAddressNamesPipe } from "./pipes/entities-to-address-names.pipe";
import { EntityToInfoAndAddressPipe } from "./pipes/entity-to-info-and-address.pipe";
import { EtablissementHasTokenUsedPipe } from "./pipes/etablissement-has-token-used.pipe";
import { EtablissementSubtitlePipe } from "./pipes/etablissement-subtitle.pipe";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { FindFunctionalDeclarationInListPipe } from "./pipes/find-functional-declaration-in-list.pipe";
import { FloorPipe } from "./pipes/floor.pipe";
import { FollowingDayPipe } from "./pipes/following-day.pipe";
import { FunctionalDeclarationSubtitlePipe } from "./pipes/functional-declaration-subtitle.pipe";
import { GetAssujettiSurfaceTextPipe } from "./pipes/get-assujetti-surface-text.pipe";
import { GetCondominiumFromEfaStatusPipe } from "./pipes/get-condominium-from-efa-status.pipe";
import { GetEfaStatusDescriptionPipe } from "./pipes/get-efa-status-description.pipe";
import { GetFromEstablishmentAddressPipe } from "./pipes/get-from-establishment-address.pipe";
import { GetFunctionalDeclarationAroundCurrentPipe } from "./pipes/get-functional-declaration-arround-current.pipe";
import { GetFunctionalDeclarationCountPipe } from "./pipes/get-functional-declaration-count.pipe";
import { GetFunctionalLabelIdentifierPipe } from "./pipes/get-functional-label-identifier.pipe";
import { GetIconFromEfaStatusPipe } from "./pipes/get-icon-from-efa-status.pipe";
import { GetLabelEstablishmentPipe } from "./pipes/get-label-establishment.pipe";
import { GetLabelFromEfaStatusPipe } from "./pipes/get-label-from-efa-status.pipe";
import { GetOwnerFromEfaStatusPipe } from "./pipes/get-owner-from-efa-status.pipe";
import { GetPcePipe } from "./pipes/get-pce.pipe";
import { GetPrmPipe } from "./pipes/get-prm.pipe";
import { GetReportPipe } from "./pipes/get-report.pipe";
import { GroupHasTokenUsedPipe } from "./pipes/group-has-token-used.pipe";
import { HasHoleInConsumptionRowPipe } from "./pipes/has-hole-in-consumption-row.pipe";
import { HasLogisticCoolingPipe } from "./pipes/has-logistic-cooling.pipe";
import { HasNotDoneStepPipe } from "./pipes/has-not-done-step.pipe";
import { HeatModifierTypeDisplayPipe } from "./pipes/heat-modifier-type-display.pipe";
import { IconFromMimeTypePipe } from "./pipes/icon-from-mime-type.pipe";
import { InArrayPipe } from "./pipes/in-array.pipe";
import { InCategoriesPipe } from "./pipes/in-categories.pipe";
import { InSetPipe } from "./pipes/in-set.pipe";
import { IndicatorFromCodePipe } from "./pipes/indicator-from-code.pipe";
import { IsActivityCategoryEntriesOverlappingPipe } from "./pipes/is-activity-category-entries-overlaping.pipe";
import { IsActivityCategoryPipe } from "./pipes/is-activity-category.pipe";
import { IsAddBlockPipe } from "./pipes/is-add-block.pipe";
import { IsAssujettiPipe } from "./pipes/is-assujetti.pipe";
import { IsElectricCarDockEntriesOverlappingPipe } from "./pipes/is-electric-car-dock-entries-overlapping.pipe";
import { IsIrvePipe } from "./pipes/is-irve.pipe";
import { IsMultiOccupationPipe } from "./pipes/is-multi-occupation.pipe";
import { IsOwnedAssujettiPipe } from "./pipes/is-owned-assujetti.pipe";
import { IsSegmentFunctionalPipe } from "./pipes/is-segment-functional.pipe";
import { LockedFunctionalDeclarationsForYearPipe } from "./pipes/locked-functional-declarations-for-year";
import { LabelFromIdentifierPipe } from "./pipes/label-from-identifier.pipe";
import { MapGetPipe } from "./pipes/map-get.pipe";
import { MaxEndForEnergyTypePipe } from "./pipes/max-end-for-energy-type.pipe";
import { MaxPipe } from "./pipes/max.pipe";
import { MinStartForEnergyTypePipe } from "./pipes/min-start-for-energy-type.pipe";
import { MinPipe } from "./pipes/min.pipe";
import { ModifierTypeToSurfaceNamePipe } from "./pipes/modifier-type-to-surface-name.pipe";
import { MySurfaceAssujettiPipe } from "./pipes/my-surface-assujetti.pipe";
import { NewParcelNamePipe } from "./pipes/new-parcel-name.pipe";
import { NoValueForYearPipe } from "./pipes/no-value-for-year.pipe";
import { NumberUnitObjectPipe } from "./pipes/number-unit-object.pipe";
import { NumberUnitPipe } from "./pipes/number-unit.pipe";
import { ObjectEqualsPipe } from "./pipes/object-equals.pipe";
import { ObjectKeysPipe } from "./pipes/object-keys.pipe";
import { OnCoolodwnPipe } from "./pipes/on-coolodwn.pipe";
import { ParcelFromNamePipe } from "./pipes/parcel-from-name.pipe";
import { ParcelIdPipe } from "./pipes/parcel-id.pipe";
import { ParcelNamePipe } from "./pipes/parcel-name.pipe";
import { PeriodRangePipe } from "./pipes/period-range.pipe";
import { ReasonToTextPipe } from "./pipes/reason-to-text.pipe";
import { ReplacePipe } from "./pipes/replace.pipe";
import { SortActivityCategoriesPerStartPipe } from "./pipes/sort-activity-categories-per-start.pipe";
import { SortConsumptionsPipe } from "./pipes/sort-consumptions.pipe";
import { SortIRVEPerStartPipe } from "./pipes/sort-irveper-start.pipe";
import { StationFromIdPipe } from "./pipes/station-from-id.pipe";
import { StepActiveFromPagePipe } from "./pipes/step-active-from-page.pipe";
import { StepTodoPercentagePipe } from "./pipes/step-todo-percentage.pipe";
import { StepTodoPipe } from "./pipes/step-todo.pipe";
import { SubCategoryFromCodePipe } from "./pipes/sub-category-from-code.pipe";
import { SumCategoryPeriodPipe } from "./pipes/sum-category-period.pipe";
import { SumCategorySurfacePerYearPipe } from "./pipes/sum-category-surface-per-year.pipe";
import { SumInvoicePerYearPipe } from "./pipes/sum-invoice-per-year.pipe";
import { SurfaceAssujettiPipe } from "./pipes/surface-assujetti.pipe";
import { TimestampPercentagePipe } from "./pipes/timestamp-percentage.pipe";
import { TimestampToDatePipe } from "./pipes/timestamp-to-date.pipe";
import { ToStringPipe } from "./pipes/to-string.pipe";
import { UniqueSubCategoriesPerNamePipe } from "./pipes/unique-categories.pipe";
import { YearForDateAndPeriodPipe } from "./pipes/year-for-date-and-period.pipe";
import { AuthenticationService } from "./services/authentication.service";
import { NavbarService } from "./services/navbar.service";
import { UserStateService } from "./services/user-state.service";
import { VersionService } from "./services/version.service";
import { ChoiceEntityModalComponent } from "./components/navigation/stepper/csv/choice-entity-modal/choice-entity-modal.component";
import { ConsumeAnnualTokenModalComponent } from "./components/navigation/stepper/functional-entity-stepper/declaration-conso/consumption-table/consume-annual-token-modal/consume-annual-token-modal.component";
import { PartialCsvDownloadComponent } from "./components/navigation/stepper/csv/consumptions-csv/partial-csv-download/partial-csv-download.component";
import { BuyTokenTypeFormComponent } from "./components/navigation/buy-token/buy-token-form/buy-token-type-form/buy-token-type-form.component";
import { DescriptionEditModalComponent } from "./components/navigation/stepper/siren-form/description-edit-modal/description-edit-modal.component";
import { StartDateEditComponent } from "./components/start-edit-modal/start-date-edit.component";
import { DownloadAllCsvModalComponent } from "./components/navigation/stepper/reference-year-summary/download-all-csv-modal/download-all-csv-modal.component";
import { IsInDomTomPipe } from "./pipes/is-in-dom-tom.pipe";
import { YearSliderComponent } from "./components/navigation/stepper/benchmark-modal/year-slider/year-slider.component";
import { CsvDataWillBePartialPipe } from "./pipes/csv-data-will-be-partial.pipe";
import { DeclarationWasImportedPipe } from "./pipes/declaration-was-imported.pipe";
import { GetReferenceYearForFunctionalDeclarationPipe } from "./pipes/get-reference-year-for-fd.pipe";
import { ImportEfaModalComponent } from "./components/navigation/stepper/siren-form/import-efa-modal/import-efa-modal.component";
import { CsvDropZoneComponent } from "./components/navigation/stepper/siren-form/import-efa-modal/csv-drop-zone/csv-drop-zone.component";
import { CheckSirenForCsvPipe } from "./pipes/check-siren-for-csv.pipe";
import { ImportDeclarationModalComponent } from "./components/navigation/my-declarations/import-declaration-modal/import-declaration-modal.component";
import { EntitiesNotConcernedByYearPipe } from "./pipes/entities-not-concerned-by-year.pipe";
import { ZodSafeParsePipe } from "./pipes/zod-safe-parse.pipe";
import { ConfirmNewlyLiableComponent } from "./components/navigation/user-info-form/confirm-newly-liable/confirm-newly-liable.component";
import { NewlyLiableInputComponent } from "./components/navigation/user-info-form/newly-liable-input/newly-liable-input.component";
import { ChoiceIsOwnerPipe } from "./pipes/choice-is-owner.pipe";
import { ConsumptionsCsvComponent } from "./components/navigation/stepper/csv/consumptions-csv/consumptions-csv.component";
import { OperatApiInteractionsComponent } from "./components/navigation/stepper/csv/operat-api-interactions/operat-api-interactions.component";
import { PartialApiUploadComponent } from "./components/navigation/stepper/csv/operat-api-interactions/partial-api-upload/partial-api-upload.component";
import { ApiErrorsModalComponent } from "./components/navigation/stepper/csv/operat-api-interactions/api-errors-modal/api-errors-modal.component";
import { OperatApiErrorMessagePipe } from "./pipes/operat-api-error-message.pipe";
import { GetConsoStateForCsvPipe } from "./pipes/get-conso-state-for-csv.pipe";
import { OperatSearchFormComponent } from "./components/navigation/user-info-form/operat-search-form/operat-search-form.component";
import { CompleteFunctionalDeclarationFromApiComponent } from "./components/navigation/stepper/functional-entity-stepper/complete-functional-declaration-from-api/complete-functional-declaration-from-api.component";
import { ApiAccessErrorModalComponent } from "./components/navigation/user-info-form/operat-search-form/api-access-error-modal/api-access-error-modal.component";
import { OperatImportLoadingComponent } from "./components/navigation/user-info-form/operat-import-loading/operat-import-loading.component";
import { ImportReferencesErrorComponent } from "./components/navigation/stepper/siren-form/import-references-error/import-references-error.component";

registerLocaleData(localeFr);

@NgModule({
	declarations: [
		AppComponent,
		StepperComponent,
		UserInfoFormComponent,
		SirenFormComponent,
		BuildingMapComponent,
		QualificationFormMapComponent,
		LoginComponent,
		WelcomeComponent,
		LogoutComponent,
		MyDeclarationsComponent,
		NavigationComponent,
		AreaHelpModalComponent,
		CategoryKeyToLabelPipe,
		ResetPasswordComponent,
		AuthorizationListModalComponent,
		CategoryListComponent,
		SurfaceTabsComponent,
		ConsumptionTableComponent,
		ConsoListComponent,
		AssetDeclarationComponent,
		FunctionalEntityStepperComponent,
		NewDeclarationModalComponent,
		DeleteGroupConfirmModalComponent,
		AgreementsComponent,
		CsvComponent,
		EditAddressModalComponent,
		AddressToStringPipe,
		AsHolderDisplayTypePipe,
		AsStreetTypePipe,
		HelpComponent,
		HelpDialogComponent,
		AgreementModalComponent,
		EtablissementSubtitlePipe,
		ParcelNamePipe,
		RegisterComponent,
		IsAssujettiPipe,
		AsNumberPipe,
		AsRecordPipe,
		GetNumberFromMaskPipe,
		HasOneFieldGreaterThanZeroPipe,
		InputConfirmComponent,
		GetPrmPipe,
		GetPcePipe,
		CloneFeatureCollectionPipe,
		CanGetAutomaticallyPipe,
		CantGetAutomaticallyReasonPipe,
		GetLabelEstablishmentPipe,
		GetAssujettiSurfaceTextPipe,
		ConsumeTokenDialogComponent,
		TokenButtonComponent,
		InputRestrictionDirective,
		ProgressModalComponent,
		StandaloneProgressModalComponent,
		TokenTransferModalComponent,
		OnCoolodwnPipe,
		AuthorizationViewComponent,
		ResourceLinkComponent,
		StepTodoPipe,
		StepTodoPercentagePipe,
		AssetMapComponent,
		YearReferenceComponent,
		AddValueModalComponent,
		ActivityCategoryModalComponent,
		CategoryFromCodePipe,
		SubCategoryFromCodePipe,
		IndicatorFromCodePipe,
		HeatModifierTypeDisplayPipe,
		TimestampToDatePipe,
		UniqueSubCategoriesPerNamePipe,
		TimestampPercentagePipe,
		IsActivityCategoryEntriesOverlappingPipe,
		ConsumptionTableRowComponent,
		EnergyTypeTitlePipe,
		NoValueForYearPipe,
		PeriodRangePipe,
		DateToTimestampPipe,
		DisplayableConsumptionEntryPipe,
		CsvHelpModalComponent,
		ObjectKeysPipe,
		CeilPipe,
		FloorPipe,
		ConsumptionEntryByYearPipe,
		EnergyTypeFromStringPipe,
		AddRowModalComponent,
		EnergyTypeUnitPipe,
		MinStartForEnergyTypePipe,
		MaxEndForEnergyTypePipe,
		AgreementForSubCategoryPipe,
		MinPipe,
		MaxPipe,
		EntitiesToAddressNamesPipe,
		EntityToInfoAndAddressPipe,
		EnergyTypeValueDisplayPipe,
		ModifierTypeToSurfaceNamePipe,
		NumberUnitPipe,
		ReferenceYearSummaryComponent,
		SortConsumptionsPipe,
		ConfirmationModalComponent,
		NotFoundComponent,
		AdvancementChipComponent,
		StepActiveFromPagePipe,
		IsSegmentFunctionalPipe,
		CanReachStepPipe,
		ImportCsvModalComponent,
		EtablissementHasTokenUsedPipe,
		GroupHasTokenUsedPipe,
		ForgotPasswordComponent,
		SumCategorySurfacePerYearPipe,
		ElectricCarDockModalComponent,
		IsElectricCarDockEntriesOverlappingPipe,
		DisplayFieldCategoryPipe,
		MapGetPipe,
		CollapsableStatePipe,
		CanNotReachStepPipe,
		ReasonToTextPipe,
		FunctionalDeclarationSubtitlePipe,
		LoadingComponent,
		InArrayPipe,
		AddDeliveryValuesModalComponent,
		ImportDeliveryCsvModalComponent,
		CsvDeliveryHelpModalComponent,
		SumInvoicePerYearPipe,
		YearForDateAndPeriodPipe,
		AreDatesWrongPipe,
		AreAnyRepartitionKeysDatesWrongPipe,
		ContainsPipe,
		GetFunctionalDeclarationAroundCurrentPipe,
		DeclarationFunctionalListDialogComponent,
		HasNotDoneStepPipe,
		GroupPaneComponent,
		NewParcelNamePipe,
		ParcelIdPipe,
		MyMapComponent,
		DeclarationRowComponent,
		DeclarationFunctionalListComponent,
		GetFunctionalDeclarationCountPipe,
		SumCategoryPeriodPipe,
		AbsPipe,
		MySurfaceAssujettiPipe,
		BuildingModalComponent,
		SurfaceModalComponent,
		BuildingAssujettiPipe,
		SurfaceAssujettiPipe,
		ParcelModalComponent,
		IsOwnedAssujettiPipe,
		InSetPipe,
		ToStringPipe,
		EnergyCategoriesLabelPipe,
		IsMultiOccupationPipe,
		IsOwnedAssujettiPipe,
		PaginationComponent,
		IsOwnedAssujettiPipe,
		ChangelogComponent,
		RepresentativeSettingsComponent,
		ColorPickerComponent,
		ObjectEqualsPipe,
		PaymentComponent,
		BuyTokenComponent,
		PaymentSliderComponent,
		FirstConnectionModalComponent,
		IdentifierModalComponent,
		AsSiretPipe,
		AsSirenPipe,
		AsRnaPipe,
		AsOtherPipe,
		LabelFromIdentifierPipe,
		IdentifierViewComponent,
		AsSirenStructurePipe,
		AsRnaStructurePipe,
		AsOtherStructurePipe,
		GetFunctionalLabelIdentifierPipe,
		AddFunctionalEntityModalComponent,
		GetCondominiumFromEfaStatusPipe,
		GetOwnerFromEfaStatusPipe,
		GetFromEstablishmentAddressPipe,
		GetEfaStatusDescriptionPipe,
		HelperModalComponent,
		SortActivityCategoriesPerStartPipe,
		IsActivityCategoryPipe,
		MyProfileComponent,
		AddressFormComponent,
		AvatarComponent,
		ColorPickerComponent,
		GetIconFromEfaStatusPipe,
		GetLabelFromEfaStatusPipe,
		ReplacePipe,
		ImageInputComponent,
		AgreementContainerComponent,
		AgreementComponent,
		BuyTokenFormComponent,
		RegisterFormComponent,
		AsRefEtatPipe,
		AsRefEtatLessPipe,
		PdfReportComponent,
		YearReferenceDataComponent,
		ReferenceYearSummaryTableComponent,
		ParcelFromNamePipe,
		StationFromIdPipe,
		ActivityCategoryTableComponent,
		ConsumptionTableExportComponent,
		OptionalDataBuildingComponent,
		ApplyPipe,
		AsAutrePipe,
		OptionalBuildingDataSelectComponent,
		NewVersionModalComponent,
		InCategoriesPipe,
		ProofFilesModalsComponent,
		FileButtonComponent,
		IconFromMimeTypePipe,
		FileSizePipe,
		CountDeclarationsPipe,
		CountEntitiesPipe,
		CorrectedValuePipe,
		AllConsumptionsIncompletePipe,
		HasLogisticCoolingPipe,
		ProgressPercentageComponent,
		LotNumberModalComponent,
		BenchmarkModalComponent,
		BenchmarkGraphComponent,
		CustomFormComponent,
		EditUmpteenthModalComponent,
		AssujettisementCasePipe,
		GetReportPipe,
		SiretInputComponent,
		SirenInputComponent,
		SortIRVEPerStartPipe,
		IsIrvePipe,
		FindFunctionalDeclarationInListPipe,
		CategoryStartPipe,
		DeclarationsCreationComponent,
		FollowingDayPipe,
		AddressListPipe,
		StatsComponent,
		StatChartMapComponent,
		StatChartPerEnergyComponent,
		StatChartPerCategoryComponent,
		IsAddBlockPipe,
		ExportCsvModalComponent,
		CheckDeclarationStatePipe,
		CheckFunctionalDeclarationStatePipe,
		CheckErrorsFormatPipe,
		CheckErrorsEntityFormatPipe,
		HasHoleInConsumptionRowPipe,
		NumberUnitObjectPipe,
		ChoiceEntityModalComponent,
		ConsumeAnnualTokenModalComponent,
		PartialCsvDownloadComponent,
		BuyTokenTypeFormComponent,
		AgreementCanEditPipe,
		DescriptionEditModalComponent,
		StartDateEditComponent,
		DownloadAllCsvModalComponent,
		YearSliderComponent,
		LockedFunctionalDeclarationsForYearPipe,
		CsvDataWillBePartialPipe,
		IsInDomTomPipe,
		DeclarationWasImportedPipe,
		GetReferenceYearForFunctionalDeclarationPipe,
		ImportEfaModalComponent,
		CsvDropZoneComponent,
		CheckSirenForCsvPipe,
		ImportDeclarationModalComponent,
		EntitiesNotConcernedByYearPipe,
		ZodSafeParsePipe,
		ConfirmNewlyLiableComponent,
		NewlyLiableInputComponent,
		ChoiceIsOwnerPipe,
		ConsumptionsCsvComponent,
		OperatApiInteractionsComponent,
		PartialApiUploadComponent,
		ApiErrorsModalComponent,
		OperatApiErrorMessagePipe,
		GetConsoStateForCsvPipe,
		OperatSearchFormComponent,
		CompleteFunctionalDeclarationFromApiComponent,
		ApiAccessErrorModalComponent,
		OperatImportLoadingComponent,
		ImportReferencesErrorComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,

		MatStepperModule,
		MatInputModule,
		MatFormFieldModule,
		MatButtonModule,
		MatRadioModule,
		MatCheckboxModule,
		MatExpansionModule,
		MatProgressBarModule,
		MatListModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatTabsModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatTableModule,
		MatSnackBarModule,
		MatSidenavModule,
		MatStepperModule,
		MatInputModule,
		MatFormFieldModule,
		MatButtonModule,
		MatRadioModule,
		MatCheckboxModule,
		MatExpansionModule,
		MatProgressBarModule,
		MatListModule,
		MatAutocompleteModule,
		MatProgressSpinnerModule,
		MatDialogModule,
		MatTabsModule,
		MatSelectModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatTableModule,
		MatSnackBarModule,
		MatMenuModule,
		MatBadgeModule,

		NgxMapLibreGLModule,
		RouterModule,

		HttpClientModule,
		MatProgressSpinnerModule,
		MatIconModule,
		MatCardModule,
		NgSelectModule,
		MatTableModule,
		MatTooltipModule,
		NgScrollbarModule,
		MatSlideToggleModule,
		MatSliderModule,
		TourMatMenuModule,
		MarkdownModule.forRoot(),
		CountUpModule,
	],
	providers: [
		{
			// processes all errors
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useFactory: function (router: Router, userState: UserStateService, authentication: AuthenticationService) {
				return new AuthInterceptor(router, userState, authentication);
			},
			multi: true,
			deps: [Router, UserStateService, AuthenticationService],
		},
		{
			provide: HTTP_INTERCEPTORS,
			useFactory: function (versionService: VersionService) {
				return new VersionInterceptor(versionService);
			},
			multi: true,
			deps: [VersionService],
		},
		{ provide: LOCALE_ID, useValue: "fr-FR" },
		NavbarService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
