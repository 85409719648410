import { Pipe, PipeTransform } from "@angular/core";
import { OperatApiErrorType } from "../models/OperatApiErrorType";

@Pipe({
	name: "operatApiErrorMessage",
})
export class OperatApiErrorMessagePipe implements PipeTransform {
	transform(code: string, declaredYear: string): string {
		switch (code) {
			case OperatApiErrorType.INVALID_CLIENT:
				return "La clé utilisateur renseignée est incorrecte. Merci d'utiliser la clé d'un utilisateur ayant accès à la structure.";
			case OperatApiErrorType.NOT_AUTHORIZED:
				return "Le tiers n'est pas autorisé sur cette structure. Rendez-vous sur le compte de votre structure OPERAT afin de renseigner Green Systèmes comme tiers, dans l'onglet \"Paramétrage - Echange par API\".";
			case OperatApiErrorType.UNKNOWN_REFERENCE:
				return "Référence OPERAT inconnue.";
			case OperatApiErrorType.ALREADY_DECLARED:
				return `La déclaration de consommation pour ${declaredYear === "REFERENCE" ? "l'année de référence" : declaredYear} a déjà été entamée / effectuée. Pour déposer votre déclaration sur OPERAT, il sera nécessaire de supprimer celle existante sur la plateforme de l'ADEME.`;
			case OperatApiErrorType.CATEGORY_ERROR:
				return "La catégorie d'activité principale renseignée doit correspondre à la catégorie d'au moins une des sous-catégories renseignées pour la déclaration.";
			case OperatApiErrorType.BAD_PERIOD:
				return "Votre structure assujettie n'est pas partie prenante de cette EFA sur la période de consommations.";
			default:
				return `Erreur inconnue`;
		}
	}
}
