<app-welcome>
	<div>
		<h1 class="text-secondary" style="font-size: 37px; margin-bottom: 15px">Définissez votre mot de passe</h1>
		<div (keydown.enter)="testCredentials()">
			<p class="text-gray" style="margin-top: 5px; font-weight: bold">
				Veuillez définir un nouveau mot de passe et le confirmer.
			</p>
			<p class="text-gray" style="font-weight: bold">Nouveau mot de passe</p>
			<input
				class="custom-input"
				style="width: 100%"
				type="password"
				(ngModelChange)="resetError()"
				[placeholder]="'Entrez votre nouveau mot de passe'"
				[(ngModel)]="newPassword"
			/>
			<p class="text-gray" style="font-weight: bold">Confirmation du mot de passe</p>
			<input
				class="custom-input"
				style="width: 100%"
				type="password"
				(ngModelChange)="resetError()"
				[placeholder]="'Confirmez votre mot de passe'"
				[(ngModel)]="confirmPassword"
			/>
			<p *ngIf="error" class="danger">{{ error }}</p>
			<button
				mat-flat-button
				[class]="{
					'btn-accent2': true,
					'btn-loading': this.loading
				}"
				style="width: 100%; margin-top: 25px"
				type="submit"
				(click)="testCredentials()"
			>
				DÉFINIR MON MOT DE PASSE
			</button>
		</div>
	</div>
</app-welcome>
