<tr class="separator"></tr>
<tbody class="table-row">
	<tr>
		<td style="padding-left: 10px" class="pointer" (click)="toggleDevelop()">
			<div class="declaration-title" style="justify-content: left">
				<div style="display: inline; text-align: left; justify-content: left">
					<div class="primary d-flex m-0 bold" style="align-items: center">
						{{ declaration.name }}
						<mat-icon
							class="expand-icon"
							[style]="{ transform: developing || develop ? 'rotate(0deg)' : 'rotate(90deg)' }"
							>expand_less
						</mat-icon>
					</div>
					<p class="primary-light" style="margin: 0; font-size: 15px">
						<ng-container *ngIf="declaration.structure | asSirenStructure as structure">
							{{ structure.siren }}{{ structure.maybeRna ? "/" + structure.maybeRna : "" }}
						</ng-container>
						<ng-container *ngIf="declaration.structure | asRnaStructure as structure">
							{{ structure.rna }}
						</ng-container>
						<ng-container *ngIf="declaration.structure | asOtherStructure as structure">
							{{ structure.id }}
						</ng-container>
					</p>
					<span class="text-gray" *ngFor="let count of [declaration.declarations_functional.length]">
						{{ count }} entité{{ count > 1 ? "s" : "" }} fonctionnelle{{ count > 1 ? "s" : "" }}
					</span>
				</div>
			</div>
		</td>
		<ng-container *ngFor="let page of PROGRESS_PAGES">
			<td (click)="stepActionEmitter.emit({ declaration, page })">
				<div>
					<app-advancement-chip [percent]="declaration | stepTodoPercentage: page"></app-advancement-chip>
				</div>
			</td>
		</ng-container>
		<td>
			<ng-container *ngIf="declaration | checkDeclarationState as errorList">
				<mat-icon
					*ngIf="errorList.length > 0"
					[matTooltip]="errorList | checkErrorsFormat"
					class="danger"
					matTooltipClass="tooltip-linebreak"
					>warning
				</mat-icon>
			</ng-container>
		</td>
		<td>
			<div>
				<button mat-icon-button [matMenuTriggerFor]="menu">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item (click)="goToStats(declaration.declaration_id)">
						<mat-icon>bar_chart</mat-icon>
						<span>Statistiques</span>
					</button>
					<button mat-menu-item (click)="openGreenExport(declaration)">
						<img
							src="assets/img/white-green-logo.svg"
							style="height: 24px; margin-right: 16px; filter: contrast(0); vertical-align: middle"
						/>
						<span>Exporter vers la Green Solution</span>
					</button>
					<button
						mat-menu-item
						(click)="openDeclarationAuthorizationListDialog(declaration, group, user.user_id ?? '')"
					>
						<mat-icon>supervisor_account</mat-icon>
						<span>Utilisateurs</span>
					</button>
					<button
						[disabled]="isTokenUsedInDeclaration"
						[matTooltip]="isTokenUsedInDeclaration ? 'Vous avez dépensé des crédits pour cette déclaration' : ''"
						mat-menu-item
						(click)="delete()"
					>
						<mat-icon>delete_forever</mat-icon>
						<span>Supprimer</span>
					</button>
				</mat-menu>
			</div>
		</td>
	</tr>
	<tr>
		<td class="p-0" [colSpan]="2 + PROGRESS_PAGES.length">
			<div class="develop" [class.developing]="developing" [class.developed]="(!developing && develop) || developAll">
				<app-declaration-functional-list
					[declarationsFunctional]="declaration.declarations_functional | apply: sortFunctionalDeclarations"
				></app-declaration-functional-list>
			</div>
		</td>
	</tr>
</tbody>
