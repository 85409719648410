'use strict';

var define = require('define-properties');
var callBind = require('call-bind');
var implementation = require('./implementation');
var getPolyfill = require('./polyfill');
var shim = require('./shim');
var polyfill = callBind(getPolyfill(), Object);
define(polyfill, {
  getPolyfill: getPolyfill,
  implementation: implementation,
  shim: shim
});
module.exports = polyfill;